import React, { useState, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Table, Typography, message } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { FileSearchOutlined } from '@ant-design/icons'
import { NumberParam, useQueryParam } from 'use-query-params'
import { getProjects } from '../../Actions/projectsApi'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PaginationComponent } from '../../components/Common'
import withTitleMetaData from '../../hocs/withTitleMetaData'

const { Text } = Typography
// const { confirm } = Modal

function Projects() {
  // Projects.propTypes = {
  //   title: PropTypes.string,
  // }

  // const [open, setOpen] = useState(false)
  const [posts, setPosts] = useState([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageable, setPageable] = useState({})
  const [totalElements, setTotalElements] = useState(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalPages, setTotalPages] = useState(null)
  const [page, setPage] = useQueryParam('page', NumberParam)

  const {
    isLoading: isLoadingQuery,
    error,
    // refetch,
    data,
  } = useQuery({
    queryKey: ['getAllProjects', page],
    queryFn: () => getProjects(page ? page - 1 : 0),
    staleTime: 500,
    keepPreviousData: true,
    onError: (e) => {
      // API 연결이 실패한 경우에 호출됨
      console.log(e.message)
    },
  })

  const isLoading = isLoadingQuery

  // const onDelete = async (href) => {
  //   const token = localStorage.getItem('token')
  //   await fetch(`${href}`, {
  //     method: 'DELETE',
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${token}`,
  //     },
  //   }).then(() => {
  //     refetch()
  //   })
  // }

  // const onClose = () => {
  //   setOpen(false)
  // }

  // const showDeleteConfirm = (href) => {
  //   confirm({
  //     title: '정말로 삭제하시겠습니까?',
  //     icon: <ExclamationCircleFilled />,
  //     content: "삭제를 원하시면 'Yes' 를 클릭하세요.",
  //     okText: 'Yes',
  //     okType: 'danger',
  //     cancelText: 'No',
  //     onOk() {
  //       onClose()
  //       onDelete(href)
  //     },
  //     onCancel() {
  //       console.log('Cancel')
  //     },
  //   })
  // }

  const columns = [
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id',
      width: '7%',
    },
    {
      key: 'title',
      title: 'Title',
      dataIndex: 'title',
      width: '25%',
    },
    {
      key: 'userId',
      title: 'User ID',
      dataIndex: 'userId',
      width: '10%',
    },
    {
      key: 'workspaceId',
      title: 'Workspace ID',
      dataIndex: 'workspaceId',
      width: '10%',
    },
    {
      key: 'updatedAt',
      title: 'Update date',
      dataIndex: 'updatedAt',
      render: (t, r) => <Text type="secondary">{moment(r.updatedAt).format('YYYY-MM-DD HH:mm')}</Text>,
      width: '15%',
    },
    {
      key: 'createdAt',
      title: 'Creation date',
      dataIndex: 'createdAt',
      render: (t, r) => <Text type="secondary">{moment(r.createdAt).format('YYYY-MM-DD HH:mm')}</Text>,
      width: '15%',
    },
    {
      key: 'View',
      title: 'View',
      render: (t, r) => (
        <Link to={`/project/${r.id}`}>
          <FileSearchOutlined />
        </Link>
      ),
    },
  ]

  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const showDrawer = (record) => {
  //   setOpen(true)
  //   setDrawerData(record)
  // }

  // const drawerDataObject = (textData) =>
  //   textData || (
  //     <Text disabled italic>
  //       no data
  //     </Text>
  //   )

  const [messageApi, contextHolder] = message.useMessage()

  /**
   * API 에러 메시지 UI
   * @param msg
   */
  const errorMsg = (msg) => {
    messageApi.open({
      type: 'error',
      content: msg,
    })
  }

  useEffect(() => {
    if (data) {
      setPosts(data.content)
      setPageable(data.pageable)
      setTotalElements(data.totalElements)
      setTotalPages(data.totalPages - 1)
    }
  }, [data])

  return (
    <div>
      {contextHolder}
      {error && errorMsg(error.message)}

      <Table
        columns={columns}
        dataSource={posts}
        rowKey={(record) => `${record.createdAt}-${Math.floor(Math.random() * 100)}${1}`}
        loading={isLoading}
        pagination={{
          total: totalElements,
          current: page,
          onChange: (newPage) => setPage(newPage),
          position: ['bottomCenter'],
        }}
      />

      {/* <Drawer title={drawerData.title} size="large" placement="right" onClose={onClose} open={open}>
        <Descriptions title="Project Info" size="small" layout="vertical" bordered>
          <Descriptions.Item label="User ID">{drawerDataObject(drawerData.userId)}</Descriptions.Item>
          <Descriptions.Item label="Project ID">{drawerDataObject(drawerData.projectId)}</Descriptions.Item>
          <Descriptions.Item label="Company">{drawerDataObject(drawerData.company)}</Descriptions.Item>
          <Descriptions.Item label="existAudio">{drawerDataObject(drawerData.existAudio)}</Descriptions.Item>
          <Descriptions.Item label="Exposed">{drawerDataObject(drawerData.exposed)}</Descriptions.Item>
        </Descriptions>

        <Descriptions layout="vertical" bordered style={{ marginTop: '24px' }}>
          <Descriptions.Item label="Contents">{drawerDataObject(drawerData.contents)}</Descriptions.Item>
        </Descriptions>

        <Descriptions layout="vertical" size="small" bordered style={{ marginTop: '24px' }}>
          <Descriptions.Item label="Creation Date">
            <Text type="secondary">
              {moment(drawerDataObject(drawerData.createdAt)).format('YYYY-MM-DD, HH:mm:ss')}
            </Text>
          </Descriptions.Item>
          <Descriptions.Item label="Updated Date">
            <Text type="secondary">
              {moment(drawerDataObject(drawerData.updatedAt)).format('YYYY-MM-DD, HH:mm:ss')}
            </Text>
          </Descriptions.Item>
          <Descriptions.Item label="Deleted Date">
            <Text type="secondary">{moment(drawerData.deletedAt).format('YYYY-MM-DD, HH:mm:ss')}</Text>
          </Descriptions.Item>
        </Descriptions>
        <Col style={{ marginTop: '24px' }}>
          <Button danger onClick={() => showDeleteConfirm(drawerData._links.self.href)}>
            Delete
          </Button>
        </Col>
      </Drawer> */}
    </div>
  )
}
export default withTitleMetaData(Projects)
