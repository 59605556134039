import { useQuery } from '@tanstack/react-query'
import { Col, Row, Typography } from 'antd'
import { workspaceQueries } from '../../../../../Actions'
import RechargeCredits from './RechargeCredits'
import RetrieveCredits from './RetrieveCredits'

type Props = {
  workspaceId: number
}

export default function WorkspaceCredits({ workspaceId }: Props) {
  const { data: credits } = useQuery({
    ...workspaceQueries.detail(Number(workspaceId))._ctx.credits,
  })

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {credits?.available !== null && credits?.available !== undefined && (
        <div>
          <Typography.Title level={4}>크레딧: {credits.available}</Typography.Title>

          <Row gutter={16}>
            <Col span={12}>
              <RechargeCredits workspaceId={workspaceId} />
            </Col>
            <Col span={12}>
              <RetrieveCredits workspaceId={workspaceId} />
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}
