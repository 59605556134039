import React, { useEffect, useState } from 'react'
import { message, Table, Typography, Tag, Button, Row, Col, Modal, DatePicker } from 'antd'
import moment from 'moment'
import { useQuery } from '@tanstack/react-query'
import { FileSearchOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { NumberParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params'
import { downloadCSV, getUsers } from '../../Actions/userApi'
import withTitleMetaData from '../../hocs/withTitleMetaData'
import useColumnSearchProps from '../../hooks/useColumnSearchProps'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { Title, Text } = Typography

function Users() {
  // Users.propTypes = {
  //   title: PropTypes.string,
  // }

  const [posts, setPosts] = useState([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageable, setPageable] = useState({})
  const [totalElements, setTotalElements] = useState(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalPages, setTotalPages] = useState(null)

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [page, setPage] = useQueryParam('page', NumberParam)
  const [filter, setFilter] = useQueryParams({
    email: StringParam,
  })
  const { getColumnSearchProps } = useColumnSearchProps({
    onSearch: (columnName, columnValue) => {
      setFilter({ [columnName]: columnValue })
    },
  })

  const {
    isLoading: isLoadingQuery,
    error,
    data,
  } = useQuery({
    queryKey: ['getUsers', filter, page],
    queryFn: () => getUsers({ ...filter, page: page ? page - 1 : 0 }),
    staleTime: 500,
    keepPreviousData: true,
    onError: (e) => {
      // API 연결이 실패한 경우에 호출됨
      console.log(e.message)
    },
  })

  const [messageApi, contextHolder] = message.useMessage()

  const isLoading = isLoadingQuery

  const columns = [
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id',
      width: '7%',
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      width: '20%',
      ...getColumnSearchProps('email', filter.email),
    },
    {
      key: 'enabled',
      title: 'Enabled',
      dataIndex: 'enabled',
      render: (text) => <Tag color={text ? 'geekblue' : 'red'}>{text ? 'True' : 'False'}</Tag>,
      width: '10%',
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      width: '15%',
    },
    {
      key: 'lastName',
      title: 'Last Name',
      dataIndex: 'lastName',
      width: '15%',
    },
    {
      key: 'provider',
      title: 'Provider',
      dataIndex: 'provider',
      width: '10%',
    },
    {
      key: 'lastLoginAt',
      title: 'Last Login',
      dataIndex: 'lastLoginAt',
      render: (t, r) => <Text type="secondary">{moment(r.lastLoginAt).format('YYYY-MM-DD HH:mm')}</Text>,
      width: '20%',
    },
    {
      key: 'createdAt',
      title: 'Creation date',
      dataIndex: 'createdAt',
      render: (t, r) => <Text type="secondary">{moment(r.createdAt).format('YYYY-MM-DD HH:mm')}</Text>,
      width: '20%',
    },
    {
      key: 'View',
      title: 'View',
      render: (t, r) => (
        <Link to={`/user/${r.id}`}>
          <FileSearchOutlined />
        </Link>
      ),
    },
  ]

  /**
   * API 에러 메시지 UI
   * @param msg
   */
  const errorMsg = (msg) => {
    messageApi.open({
      type: 'error',
      content: msg,
    })
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleDownload = async () => {
    try {
      await downloadCSV(startDate, endDate)
      setIsModalVisible(false)
      setStartDate(null)
      setEndDate(null)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (data) {
      setPosts(data.content)
      setPageable(data.pageable)
      setTotalElements(data.totalElements)
      setTotalPages(data.totalPages - 1)
    }
  }, [data])

  return (
    <div>
      <Row align="end">
        <Col>
          <Button size="large" onClick={showModal}>
            CSV
          </Button>
        </Col>
      </Row>

      <Modal
        title="Seleect Dates"
        open={isModalVisible}
        onOk={handleDownload}
        onCancel={handleCancel}
        okButtonProps={{
          disabled: !startDate || !endDate,
        }}
      >
        <Row style={{ marginTop: '16px' }}>
          <Col span={6}>Start Date:</Col>
          <Col span={18}>
            <DatePicker value={startDate} onChange={(date) => setStartDate(date)} />
          </Col>
        </Row>
        <Row style={{ marginTop: '16px' }}>
          <Col span={6}>End Date:</Col>
          <Col span={18}>
            <DatePicker value={endDate} onChange={(date) => setEndDate(date)} />
          </Col>
        </Row>
      </Modal>

      {contextHolder}
      {error && errorMsg(error.message)}

      <Table
        columns={columns}
        dataSource={posts}
        rowKey={(record) => `${record.email}-${Math.floor(Math.random() * 100)}${1}`}
        loading={isLoading}
        pagination={{
          total: totalElements,
          current: page,
          onChange: (newPage) => setPage(newPage),
          position: ['bottomCenter'],
        }}
      />

      {/* <Drawer title={drawerData?.email || 'User Info'} placement="right" size="large" onClose={onClose} open={open}>
        Workspaces
        {drawerData?.workspaceList && (
          <div style={{ marginBottom: 20 }}>
            <Typography.Title level={4}>Workspaces</Typography.Title>
            <Table
              dataSource={drawerData.workspaceList}
              pagination={false}
              columns={[
                {
                  title: 'Workspace ID',
                  dataIndex: 'workspaceId',
                  key: 'workspaceId',
                },
                {
                  title: 'Workspace Name',
                  dataIndex: 'workspaceName',
                  key: 'workspaceName',
                },
                {
                  title: 'Member Role',
                  dataIndex: 'role',
                  key: 'role',
                },
                {
                  title: 'Plan ID',
                  dataIndex: 'planId',
                  key: 'planId',
                },
                {
                  title: 'Plan Name',
                  dataIndex: 'planName',
                  key: 'planName',
                },
              ]}
            />
          </div>
        )}
      </Drawer> */}
    </div>
  )
}

export default withTitleMetaData(Users)
