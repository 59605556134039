import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RecoilRoot } from 'recoil'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
// import queryString from 'query-string'
import App from './App'
import reportWebVitals from './reportWebVitals'

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <QueryClientProvider client={queryClient}>
    <RecoilRoot>
      <BrowserRouter>
        <QueryParamProvider
          adapter={ReactRouter6Adapter}
          // See: https://www.npmjs.com/package/use-query-params
          // options={{ searchStringToObject: queryString.parse, objectToSearchString: queryString.stringify }}
        >
          <React.StrictMode>
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
          </React.StrictMode>
        </QueryParamProvider>
      </BrowserRouter>
    </RecoilRoot>
  </QueryClientProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
