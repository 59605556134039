import apiClient from '../service/api'

// GET ALL videos
export const getAllVideos = async (page) => {
  const token = localStorage.getItem('token')

  const { data } = await apiClient.get(`/v1/movies?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return data
}

export const dummy = null
