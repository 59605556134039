import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { Button, Col, Row, Table } from 'antd'
import { FileSearchOutlined, FormOutlined } from '@ant-design/icons'
import { NumberParam, useQueryParam } from 'use-query-params'
import FemaleAvatar from '../../../assets/images/female_avatar.png'
import MaleAvatar from '../../../assets/images/male_avatar.png'
import { getActingVideos } from '../../../Actions'
import withTitleMetaData from '../../../hocs/withTitleMetaData'

// const { Text } = Typography
// // eslint-disable-next-line @typescript-eslint/no-unused-vars
// const { confirm } = Modal

function ActingVideos() {
  // ActingVideos.propTypes = {
  //   title: PropTypes.string,
  // }

  // const navigate = useNavigate()

  // const [open, setOpen] = useState(false)

  // const [drawerData, setDrawerData] = useState([])
  const [posts, setPosts] = useState([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageable, setPageable] = useState({})
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [postsPerPage] = useState(10)
  const [totalElements, setTotalElements] = useState(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalPages, setTotalPages] = useState(null)
  const [page, setPage] = useQueryParam('page', NumberParam)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLoading, isError, error, isFetching, isPreviousData, data } = useQuery({
    queryKey: ['getActingVideos', page],
    queryFn: () => getActingVideos(page ? page - 1 : 0),
    staleTime: 500,
    keepPreviousData: true,
    onError: (e) => {
      // API 연결이 실패한 경우에 호출됨
      console.log(e.message)
    },
  })

  useEffect(() => {
    if (data) {
      setPosts(data.content)
      setPageable(data.pageable)
      setTotalElements(data.totalElements)
      setTotalPages(data.totalPages - 1)
      console.log(data.length)
    }
  }, [data])

  // const queryClient = useQueryClient()

  // useEffect(() => {
  //   const nextPage = page + 1
  //   queryClient.prefetchQuery(['getActingVideos', nextPage], () => getActingVideos(nextPage)) // 다음 페이지의 데이터를 미리 fetch
  // }, [page, queryClient])

  const columns = [
    {
      key: 'id',
      title: 'Id',
      dataIndex: 'id',
      width: '7%',
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
    },
    {
      key: 'gender',
      title: '성별',
      dataIndex: 'gender',
      width: '10%',
      render: (t, r) => <img src={r.gender === 'FEMALE' ? FemaleAvatar : MaleAvatar} width="40" alt={r.gender} />,
    },
    {
      key: 'View',
      title: 'View',
      render: (t, r) => (
        <Link to={`${r.id}`}>
          <FileSearchOutlined />
        </Link>
      ),
    },
  ]

  // const onRow = (record) => ({
  //   onClick: () => navigate(`/resources/videos/${record.id}`),
  // })

  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const showDrawer = (record) => {
  //   setOpen(true)
  //   setDrawerData(record)
  // }
  // const onClose = () => {
  //   setOpen(false)
  // }

  // const drawerDataObject = (textData) =>
  //   textData || (
  //     <Text disabled italic>
  //       no data
  //     </Text>
  //   )

  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [messageApi, contextHolder] = message.useMessage()

  // /**
  //  * API 에러 메시지 UI
  //  * @param msg
  //  */
  // const errorMsg = (msg) => {
  //   messageApi.open({
  //     type: 'error',
  //     content: msg,
  //   })
  // }

  return (
    <div>
      <Row justify="end">
        <Col>
          <Link to="/resources/videos/create">
            <Button type="primary" size="large">
              <FormOutlined /> 행동 영상 등록
            </Button>
          </Link>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={posts}
        size="middle"
        rowKey={(record) => record.id}
        loading={isLoading}
        // onRow={onRow}
        pagination={{
          total: totalElements,
          current: page,
          onChange: (newPage) => setPage(newPage),
          position: ['bottomCenter'],
        }}
      />

      {/* <Drawer
        title={`${drawerData.name} (${drawerData.id})`}
        size="large"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <Descriptions title="행동영상 정보" size="small" layout="vertical" bordered>
          <Descriptions.Item label="성별">
            {drawerDataObject(
              <img
                src={drawerData.gender === 'FEMALE' ? FemaleAvatar : MaleAvatar}
                width="64"
                alt={drawerData.gender}
              />,
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Thumbnail">{drawerDataObject(drawerData.thumbnail)}</Descriptions.Item>
          <Descriptions.Item label="FrameRate">{drawerDataObject(drawerData.frameRate)}</Descriptions.Item>
        </Descriptions>

        <Descriptions layout="vertical" bordered style={{ marginTop: '24px' }}>
          <Descriptions.Item label="비디오">
            {drawerDataObject(
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video controls>
                <source src={drawerData.video} type="video/mp4" />
              </video>,
            )}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions layout="vertical" bordered style={{ marginTop: '24px' }}>
          <Descriptions.Item label="Matte Mask">
            {drawerDataObject(
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video controls>
                <source src={drawerData.matteMask} type="video/mp4" />
              </video>,
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Matte Mask Threshold">
            {drawerDataObject(drawerData.matteMaskThreshold)}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions layout="vertical" bordered style={{ marginTop: '24px' }}>
          <Descriptions.Item label="Memo">{drawerDataObject(drawerData.memo)}</Descriptions.Item>
        </Descriptions>
      </Drawer> */}
    </div>
  )
}

export default withTitleMetaData(ActingVideos)
