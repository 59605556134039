import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { Table } from 'antd'
import { getGroups } from '../../Actions/groupsApi'
import withTitleMetaData from '../../hocs/withTitleMetaData'

const columns = [
  {
    key: 'groupsName',
    title: '조직명',
    dataIndex: 'title',
    render: (t, r) => <Link to={`/groups/${r.groupsNumber}`}>{r.title}</Link>,
  },
]

function Groups() {
  // Groups.propTypes = {
  //   title: PropTypes.string,
  // }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false)

  const {
    isLoading,
    error,
    data: groups,
  } = useQuery({
    queryKey: ['getGroups'],
    queryFn: () => getGroups(),
  })

  return (
    <div>
      {isLoading && <div>Loading....</div>}
      {error && error.message}

      <Table
        columns={columns}
        dataSource={groups}
        rowKey={(render) => render.id}
        loading={loading}
        pagination={{
          pageSize: 10,
          position: ['bottomCenter'],
        }}
      />
    </div>
  )
}
export default withTitleMetaData(Groups)
