import { useSetRecoilState } from 'recoil'
import { Moment } from 'moment'
import { createQueryKeys } from '@lukemorales/query-key-factory'
import apiClient from '../service/api'
import { usersAtom } from '../atoms/users'
import { PaginationParams } from '../types/list'

export const useUserActions = (token: string) => {
  const setUserInfo = useSetRecoilState(usersAtom)

  let storedToken: string | null = token
  if (!token) {
    storedToken = localStorage.getItem('token')
  }

  const config = {
    headers: {
      Authorization: `Bearer ${storedToken}`,
    },
  }

  async function getUserInfo() {
    const { data } = await apiClient.get(`/v1/auth/me`, config)
    setUserInfo(data)
    return data
  }

  return {
    getUserInfo,
  }
}

/**
 * GET Users
 * @returns {Promise<*>}
 */
export const getUsers = async (params: PaginationParams & { email?: string }) => {
  const token = localStorage.getItem('token')
  const { data } = await apiClient.get(`/v1/users`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  })
  return data
}

export const getUserDetails = async (userId: number) => {
  const token = localStorage.getItem('token')
  const response = await apiClient.get(`/v1/users/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.data
}

export const downloadCSV = async (startDate: Moment, endDate: Moment) => {
  try {
    const token = localStorage.getItem('token')
    const startDateStr = startDate.format('YYYYMMDD')
    const endDateStr = endDate.format('YYYYMMDD')
    const response = await apiClient.get(`/v1/users/csv?startDate=${startDateStr}&endDate=${endDateStr}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    })
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', `users_${startDateStr}_to_${endDateStr}.csv`)
    document.body.appendChild(link)
    link.click()
    link.remove()
  } catch (e) {
    console.log(e)
  }
}

// export const createUser = async () => {
//   const token = localStorage.getItem('token')

//   const { data } = await apiClient.post('/v1/auth/register')
// }

export const userQueries = createQueryKeys('user', {
  detail: (userId: number) => ({
    queryKey: [userId],
    queryFn: () => getUserDetails(userId),
  }),
})
