import React, { useEffect, useRef, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Typography, Descriptions, message, Button, Input, Modal, Col, Row, Form, Tag, Card } from 'antd'
import FemaleAvatar from '../../../assets/images/female_avatar.png'
import MaleAvatar from '../../../assets/images/male_avatar.png'
import { getActingVideo } from '../../../Actions'
import useInput from '../../../hooks/useInput'
import apiClient from '../../../service/api'
import withTitleMetaData from '../../../hocs/withTitleMetaData'

const { Title, Text } = Typography

const formItemLayout = {
  wrapperCol: {
    xs: {
      none: 600,
    },
    sm: {
      none: 600,
    },
  },
}

const gridStyle = {
  width: '20%',
  textAlign: 'left',
}

// 상세 dara 없을 때
function NoData() {
  return (
    <Text disabled italic>
      no data
    </Text>
  )
}

function ActingVideo() {
  // ActingVideo.propTypes = {
  //   title: PropTypes.string,
  // }

  const { videoId } = useParams()

  const [videoData, setVideoData] = useState({})

  const { data } = useQuery({
    queryKey: ['getActingVideo', videoId],
    queryFn: () => getActingVideo(videoId),
    staleTime: 500,
    onError: (e) => {
      // API 연결이 실패한 경우에 호출됨
      console.log(e.message)
    },
  })

  useEffect(() => {
    if (data) {
      setVideoData(data)
      console.log(data)
    }
  }, [data])

  const [modelIds, onChangeModelIds] = useInput([])

  const [form] = Form.useForm()
  const formRef = useRef(null)

  const [uploading, setUploading] = useState(false)
  const [disabled, setDisabled] = useState(true)

  const [formIds, setFormIds] = useState([])
  const [messageApi, contextHolder] = message.useMessage()

  /**
   * API 에러 메시지 UI
   * @param msg
   */
  const errorMsg = (msg) => {
    messageApi.open({
      type: 'error',
      content: msg,
    })
  }

  const handleSubmit = () => {
    // 새로운 값을 배열에 추가
    if (!Number.isNaN(Number(modelIds))) {
      setFormIds([...formIds, Number(modelIds)])
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleFormSubmit = (modelId) => {
    const token = localStorage.getItem('token')
    // let formData = [];
    // formData = new FormData();
    // formData.append("videoIds", JSON.stringify(formIds));

    setUploading(true)
    apiClient
      .put(
        `/v1/resources/actingvideos/${videoData.id}`,
        { modelIds: formIds },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        // API 응답을 처리하는 코드
        console.log('API Response:', res)
        if (res.status === 200) {
          Modal.success({
            title: `${formIds} 후보 매핑이 정상적으로 되었습니다.`,
          })
          setUploading(false)
          formRef.current?.resetFields()
          setDisabled(true)
        }
      })
      .catch((error) => {
        // 오류 처리 코드
        console.error('Error:', error)
        if (error) {
          errorMsg(error.message)
        }
        setUploading(false)
      })
      .finally(() => {
        setUploading(false)
        form.resetFields()
        setDisabled(true)
        setFormIds([])
      })
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const deleteIds = (e) => {
    const filtered = formIds.filter((element) => element !== e)
    setFormIds(filtered)
  }

  useEffect(() => {
    if (formIds.length >= 1) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
    console.log(formIds)
  }, [formIds])

  return (
    <div>
      <Title level={3}>
        {`"${videoData.name}"`} ({videoId})
      </Title>

      <Descriptions title="행동영상 정보" size="small" layout="vertical" bordered>
        <Descriptions.Item label="성별">
          <img src={videoData.gender === 'FEMALE' ? FemaleAvatar : MaleAvatar} width="64" alt={videoData.gender} />
        </Descriptions.Item>
        <Descriptions.Item label="Thumbnail">{videoData.thumbnail ?? <NoData />}</Descriptions.Item>
        <Descriptions.Item label="FrameRate">{videoData.frameRate ?? <NoData />}</Descriptions.Item>
      </Descriptions>

      <Descriptions layout="vertical" bordered style={{ marginTop: '24px' }}>
        <Descriptions.Item label="비디오">
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video controls>
            <source src={videoData.video} type="video/mp4" />
          </video>
        </Descriptions.Item>
      </Descriptions>

      <Descriptions layout="vertical" bordered style={{ marginTop: '24px' }}>
        <Descriptions.Item label="Matte Mask">
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video controls>
            <source src={videoData.matteMask} type="video/mp4" />
          </video>
        </Descriptions.Item>
        <Descriptions.Item label="Matte Mask Threshold">{videoData.matteMaskThreshold}</Descriptions.Item>
      </Descriptions>

      <Descriptions layout="vertical" bordered style={{ marginTop: '24px' }}>
        <Descriptions.Item label="Memo">{videoData.memo ?? <NoData />}</Descriptions.Item>
      </Descriptions>

      <Card title="Deep Learning Model List" style={{ marginTop: '32px' }}>
        {videoData.deepLearningModelList &&
          videoData.deepLearningModelList.map((model) => (
            <Card.Grid style={gridStyle} key={model.id}>
              <ul>
                <li>{model.id}</li>
                <li>
                  <Text type="secondary">{model.name}</Text>
                </li>
                <li>
                  {model.actingVideoList && model.actingVideoList.map((video) => <Tag key={video.id}>{video}</Tag>)}
                </li>
              </ul>
            </Card.Grid>
          ))}
      </Card>

      <Row justify="space-between" style={{ marginTop: '24px' }}>
        {/* <Button> */}
        {/*  <Link to={`/resources/models/update/${modelData.id}`}>정보 수정</Link> */}
        {/* </Button> */}
        <div />
        <Col>
          <Link to="/resources/videos" style={{ fontSize: '16px' }}>
            List
          </Link>
        </Col>
      </Row>

      <Descriptions title="아이바타 후보 매핑" size="small" layout="vertical" bordered style={{ marginTop: '24px' }}>
        <Descriptions.Item label="ModelIds">
          {contextHolder}
          <Form
            {...formItemLayout}
            layout="inline"
            form={form}
            ref={formRef}
            name="register-model"
            onFinish={handleSubmit}
            onFinishFailed={onFinishFailed}
            style={{
              maxWidth: 600,
            }}
            scrollToFirstError
          >
            <Form.Item
              name="modelIds"
              label="model Ids 입력"
              rules={[
                {
                  type: 'modelIds',
                  required: true,
                },
              ]}
            >
              <Input type="text" placeholder="ID 숫자만 입력" value={modelIds} onChange={onChangeModelIds} allowClear />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 0,
              }}
            >
              <Button type="primary" htmlType="submit" disabled={modelIds.length <= 1}>
                Add
              </Button>
              <Button style={{ marginLeft: '16px' }} type="default" onClick={() => setFormIds([])}>
                Clear
              </Button>
            </Form.Item>
          </Form>
          <Row style={{ padding: '24px 0' }}>
            {formIds.map((formId) => (
              <Tag
                closable
                key={formId}
                color="purple"
                onClose={(e) => {
                  e.preventDefault()
                  deleteIds(formId)
                }}
              >
                {formId}
              </Tag>
            ))}
          </Row>
          <Row>
            <Button type="primary" disabled={disabled} onClick={handleFormSubmit} loading={uploading}>
              {uploading ? 'Process...' : '후보 매핑하기'}
            </Button>
          </Row>
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}

export default withTitleMetaData(ActingVideo)
