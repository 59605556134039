import React from 'react'
import { useParams } from 'react-router-dom'
import withTitleMetaData from '../../../hocs/withTitleMetaData'
import UserBrief from './UserBrief'
import UserWorkspaceList from './UserWorkspaceList'

function User() {
  const { userId } = useParams()

  if (!userId) throw new Error('userId가 없습니다.')

  return (
    <>
      <UserBrief userId={Number(userId)} />
      <UserWorkspaceList userId={Number(userId)} />
    </>
  )
}

export default withTitleMetaData(User)
