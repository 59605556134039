import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Button, Col, Drawer, Input, message, Row, Space, Table, Tag, Typography } from 'antd'
import moment from 'moment'
import { FormOutlined, SearchOutlined } from '@ant-design/icons'
import { NumberParam, useQueryParam } from 'use-query-params'
import { getPlanDetails, getPlans } from '../../Actions/plansApi'
import withTitleMetaData from '../../hocs/withTitleMetaData'

const { Text } = Typography

function Plans() {
  // Plans.propTypes = {
  //   title: PropTypes.string,
  // }

  const [searchText, setSearchText] = useState()
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isModalOpen, setIsModalOpen] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const showModal = () => {
    setIsModalOpen(true)
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleOk = () => {
    setIsModalOpen(false)
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  /**
   * dataIndex 검색
   * @param dataIndex
   * @returns {{filterDropdown: (function({setSelectedKeys: *, selectedKeys: *, confirm: *, clearFilters: *, close: *}): *), filterIcon: (function(*): *), onFilter: (function(*, *): boolean), render: (function(*): JSX.Element|*), onFilterDropdownOpenChange: getColumnSearchProps.onFilterDropdownOpenChange}}
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getColumnSearchProps = (dataIndex) => ({
    // eslint-disable-next-line react/no-unstable-nested-components
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              })
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    // eslint-disable-next-line react/no-unstable-nested-components
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const [posts, setPosts] = useState([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageable, setPageable] = useState({})
  const [totalElements, setTotalElements] = useState(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalPages, setTotalPages] = useState(null)
  const [page, setPage] = useQueryParam('page', NumberParam)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLoading, error, refetch, data } = useQuery({
    queryKey: ['getPlans', page],
    queryFn: () => getPlans(page ? page - 1 : 0),
    staleTime: 500,
    keepPreviousData: true,
    onError: (e) => {
      // API 연결이 실패한 경우에 호출됨
      console.log(e.message)
    },
  })

  useEffect(() => {
    if (data) {
      setPosts(data.content)
      setPageable(data.pageable)
      setTotalElements(data.totalElements)
      setTotalPages(data.totalPages - 1)
    }
  }, [data])

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [drawerData, setDrawerData] = useState(null)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const showDrawer2 = async (record) => {
    try {
      const planDetails = await getPlanDetails(record.id)
      setDrawerData(planDetails)
      setDrawerOpen(true)
    } catch (e) {
      console.log(e)
    }
  }

  const showDrawer = (record) => {
    setDrawerData(record)
    setDrawerOpen(true)
  }

  const onCLoseDrawer = () => {
    setDrawerOpen(false)
  }

  const columns = [
    {
      title: 'NO',
      ket: 'index',
      render: (t, r) => <Text>{posts.indexOf(r) + 1}</Text>,
      width: '7%',
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      width: '20%',
      render: (t, r) => (
        <Button type="link" onClick={() => showDrawer(r)}>
          {t}
        </Button>
      ),
    },
    {
      key: 'price',
      title: 'Price',
      dataIndex: 'price',
      width: '13%',
    },
    {
      key: 'currency',
      title: 'Currency',
      dataIndex: 'currency',
      width: '10%',
    },
    {
      key: 'level',
      title: 'Level',
      dataIndex: 'level',
      width: '10%',
    },
    {
      key: 'activation',
      title: 'Activation',
      dataIndex: 'isActive',
      render: (text) => <Tag color={text ? 'geekblue' : 'red'}>{text ? 'True' : 'False'}</Tag>,
      width: '10%',
    },
    {
      key: 'createdAt',
      title: 'Creation date',
      dataIndex: 'createdAt',
      render: (t, r) => <Text type="secondary">{moment(r.createdAt).format('YYYY-MM-DD HH:mm')}</Text>,
      width: '20%',
    },
  ]

  const [messageApi, contextHolder] = message.useMessage()

  /**
   * API 에러 메시지 UI
   * @param msg
   */
  const errorMsg = (msg) => {
    messageApi.open({
      type: 'error',
      content: msg,
    })
  }

  return (
    <div>
      <Row justify="end">
        <Col>
          <Link to="/plan/create">
            <Button type="primary" size="large">
              <FormOutlined /> Add Plan
            </Button>
          </Link>
        </Col>
      </Row>

      <Drawer
        title={drawerData?.name || 'Plan Info'}
        placement="right"
        width={600}
        onClose={onCLoseDrawer}
        open={drawerOpen}
      >
        {/* Features */}
        {drawerData?.features && (
          <div style={{ marginBottom: 20 }}>
            <Typography.Title level={4}>Plan Info</Typography.Title>
            <Table
              dataSource={drawerData.features}
              pagination={false}
              columns={[
                {
                  title: 'Feature',
                  dataIndex: 'name',
                  key: 'feature',
                },
                {
                  title: 'Value',
                  dataIndex: 'value',
                  key: 'value',
                },
                {
                  title: 'Description',
                  dataIndex: 'description',
                  key: 'description',
                },
              ]}
            />
          </div>
        )}
      </Drawer>

      {contextHolder}
      {error && errorMsg(error.message)}
      <Table
        columns={columns}
        dataSource={posts}
        rowKey={(record) => `${record.createdAt}-${Math.floor(Math.random() * 100)}${1}`}
        loading={isLoading}
        pagination={{
          total: totalElements,
          current: page,
          onChange: (newPage) => setPage(newPage),
          position: ['bottomCenter'],
        }}
      />
    </div>
  )
}
export default withTitleMetaData(Plans)
