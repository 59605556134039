import { atom, selector } from 'recoil'
import apiClient from '../service/api'
import { loginState } from './login'
// eslint-disable-next-line import/no-cycle
import { ClientApi } from '../clients/clientApi'

const projectState = selector({
  key: 'projectState',
  get: async () => {
    const resp = await ClientApi.project.list()
    return resp.data
  },
})

const currentProjectId = atom({
  key: 'currentProjectId',
  default: undefined,
})

const currentProject = selector({
  key: 'currentProject',
  get: async ({ get }) => {
    const token = get(loginState).accessToken
    const url = `/v1/workspaces/37/projects/${get(currentProjectId)}?detail=true`
    const response = await apiClient.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    return response.data
  },
})

export { projectState, currentProjectId, currentProject }
