import React from 'react'
import {
  FundProjectionScreenOutlined,
  PieChartOutlined,
  UserOutlined,
  VideoCameraOutlined,
  AudioOutlined,
  HddOutlined,
  CrownOutlined,
  DatabaseOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  }
}

const asideItems = [
  getItem(<Link to="/dashboard">대시보드</Link>, 'dashboard', <PieChartOutlined />),
  getItem('워크스페이스', 'workspace:0', <HddOutlined />, [
    getItem(<Link to="/workspace">워크스페이스 목록</Link>, 'workspace:1'),
  ]),
  getItem('회원', 'user:0', <UserOutlined />, [
    getItem(<Link to="/user">회원 목록</Link>, 'user:1'),
    getItem(<Link to="/user/register">회원 등록</Link>, 'user:2'),
  ]),
  // getItem("조직", "group:0", <TeamOutlined />, [
  //   getItem(<Link to="/group">조직 목록</Link>, "group:1"),
  //   getItem("item", "group:2"),
  // ]),
  getItem('프로젝트', 'project:0', <FundProjectionScreenOutlined />, [
    getItem(<Link to="/project">프로젝트 목록</Link>, 'project:1'),
    getItem(<Link to="/project/create">프로젝트 생성</Link>, 'project:2'),
  ]),
  getItem('오디오', 'audio:0', <AudioOutlined />, [getItem(<Link to="/audio">오디오 목록</Link>, 'audio:1')]),
  getItem('비디오', 'video:0', <VideoCameraOutlined />, [getItem(<Link to="/video">비디오 목록</Link>, 'video:1')]),
  getItem('플랜', 'plan:0', <CrownOutlined />, [getItem(<Link to="/plan">플랜 목록</Link>, 'plan:1')]),
  getItem('리소스', 'res:0', <DatabaseOutlined />, [
    getItem(<Link to="/resources/voices">목소리 목록</Link>, 'res:1'),
    getItem(<Link to="/resources/videos">행동영상 목록</Link>, 'res:2'),
    getItem(<Link to="/resources/models">딥러닝모델 목록</Link>, 'res:3'),
    getItem(<Link to="/resources/aivatars">아이바타 목록</Link>, 'res:4'),
  ]),
]

export default asideItems
