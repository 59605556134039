import { useQuery } from '@tanstack/react-query'
import { Descriptions, Typography } from 'antd'
import React from 'react'
import { userQueries } from '../../../Actions'

type Props = {
  userId: number
}

export default function UserBrief({ userId }: Props) {
  const { data } = useQuery({
    ...userQueries.detail(userId),
  })

  return (
    <div>
      <Typography.Title level={3}>유저 정보</Typography.Title>
      <Descriptions column={2} bordered>
        <Descriptions.Item label="id">{data?.id}</Descriptions.Item>
        <Descriptions.Item label="email">{data?.email}</Descriptions.Item>
        <Descriptions.Item label="image">
          <img src={data?.imageUrl} alt="프로필이미지" />
        </Descriptions.Item>
        <Descriptions.Item label="name">{data?.name}</Descriptions.Item>
        <Descriptions.Item label="preferredLanguage">{data?.preferredLanguage}</Descriptions.Item>
        <Descriptions.Item label="role">{data?.role}</Descriptions.Item>
      </Descriptions>
    </div>
  )
}
