import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { Table, message, Button, Col, Row } from 'antd'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// import Highlighter from 'react-highlight-words'
import { FileSearchOutlined, FormOutlined } from '@ant-design/icons'
// import useInput from '../../../hooks/useInput'
// import apiClient from '../../../service/api'
// import FemaleAvatar from '../../../assets/images/female_avatar.png'
// import MaleAvatar from '../../../assets/images/male_avatar.png'
import { NumberParam, useQueryParam } from 'use-query-params'
import { getModels } from '../../../Actions'
import withTitleMetaData from '../../../hocs/withTitleMetaData'

// const { Text } = Typography
// // eslint-disable-next-line @typescript-eslint/no-unused-vars
// const { confirm } = Modal

// // eslint-disable-next-line @typescript-eslint/no-unused-vars
// const layout = {
//   labelCol: {
//     span: 0,
//   },
//   wrapperCol: {
//     span: 24,
//   },
// }

// // eslint-disable-next-line @typescript-eslint/no-unused-vars
// const formItemLayout = {
//   wrapperCol: {
//     xs: {
//       none: 600,
//     },
//     sm: {
//       none: 600,
//     },
//   },
// }

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Models({ title }) {
  // Models.propTypes = {
  //   title: PropTypes.string,
  // }

  // const [open, setOpen] = useState(false)

  // const [drawerData, setDrawerData] = useState([])
  const [posts, setPosts] = useState([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageable, setPageable] = useState({})
  const [totalElements, setTotalElements] = useState(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalPages, setTotalPages] = useState(null)
  const [page, setPage] = useQueryParam('page', NumberParam)

  const { isLoading, data } = useQuery({
    queryKey: ['getModels', page],
    queryFn: () => getModels(page ? page - 1 : 0),
    staleTime: 500,
    keepPreviousData: true,
    onError: (e) => {
      // API 연결이 실패한 경우에 호출됨
      console.log(e.message)
    },
  })

  // const navigate = useNavigate()

  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [videoIds, onChangeVideoIds] = useInput([])

  // const [form] = Form.useForm()
  // const formRef = useRef(null)

  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [uploading, setUploading] = useState(false)
  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [disabled, setDisabled] = useState(true)

  // const [formIds, setFormIds] = useState([])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [messageApi, contextHolder] = message.useMessage()

  // /**
  //  * API 에러 메시지 UI
  //  * @param msg
  //  */
  // const errorMsg = (msg) => {
  //   messageApi.open({
  //     type: 'error',
  //     content: msg,
  //   })
  // }

  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const handleSubmit = () => {
  //   // 새로운 값을 배열에 추가
  //   if (!Number.isNaN(Number(videoIds))) {
  //     setFormIds([...formIds, Number(videoIds)])
  //   }
  // }

  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const handleFormSubmit = (values) => {
  //   const token = localStorage.getItem('token')
  //   // let formData = [];
  //   // formData = new FormData();
  //   // formData.append("videoIds", JSON.stringify(formIds));

  //   setUploading(true)
  //   apiClient
  //     .put(
  //       `/v1/resources/models/${drawerData.id}`,
  //       { videoIds: formIds },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       },
  //     )
  //     .then((res) => {
  //       // API 응답을 처리하는 코드
  //       console.log('API Response:', res)
  //       if (res.status === 200) {
  //         Modal.success({
  //           title: `${formIds} 후보 매핑이 정상적으로 되었습니다.`,
  //         })
  //         setUploading(false)
  //         formRef.current?.resetFields()
  //         setDisabled(true)
  //       }
  //     })
  //     .catch((error) => {
  //       // 오류 처리 코드
  //       console.error('Error:', error)
  //       if (error) {
  //         errorMsg(error.message)
  //       }
  //       setUploading(false)
  //     })
  //     .finally(() => {
  //       setUploading(false)
  //       form.resetFields()
  //       setDisabled(true)
  //       setFormIds([])
  //     })
  // }

  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const onFinishFailed = (errorInfo) => {
  //   console.log('Failed:', errorInfo)
  // }

  // const showDrawer = async (record) => {
  //   try {
  //     const model = await getModel(record.id)
  //     setOpen(true)
  //     setDrawerData(model)
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }
  useEffect(() => {
    if (data) {
      setPosts(data.content)
      setPageable(data.pageable)
      setTotalElements(data.totalElements)
      setTotalPages(data.totalPages - 1)
    }
  }, [data])

  // const queryClient = useQueryClient()

  // useEffect(() => {
  //   const nextPage = page + 1
  //   queryClient.prefetchQuery(['getModels', nextPage], () => getModels(nextPage)) // 다음 페이지의 데이터를 미리 fetch
  // }, [page, queryClient])

  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
    },
    {
      key: 'modelType',
      title: 'Model Type',
      dataIndex: 'modelType',
    },
    {
      key: 'faceType',
      title: 'Face Type',
      dataIndex: 'faceType',
    },
    {
      key: 'View',
      title: 'View',
      render: (t, r) => (
        <Link to={`${r.id}`}>
          <FileSearchOutlined />
        </Link>
      ),
    },
  ]

  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const onRow = (record, index) => ({
  //   onClick: () => navigate(`/resources/models/${record.id}`),
  // })

  // const onClose = () => {
  //   setOpen(false)
  // }

  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const drawerDataObject = (textData) =>
  //   textData || (
  //     <Text disabled italic>
  //       no data
  //     </Text>
  //   )

  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const deleteIds = (e) => {
  //   const filtered = formIds.filter((element) => element !== e)
  //   setFormIds(filtered)
  // }

  // useEffect(() => {
  //   if (formIds.length >= 1) {
  //     setDisabled(false)
  //   } else {
  //     setDisabled(true)
  //   }
  //   console.log(formIds)
  // }, [formIds])

  return (
    <div>
      <Row justify="end">
        <Col>
          <Link to="/resources/models/create">
            <Button type="primary" size="large">
              <FormOutlined /> 딥러닝모델 등록
            </Button>
          </Link>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={posts}
        size="middle"
        rowKey={(record) => record.id}
        loading={isLoading}
        pagination={{
          total: totalElements,
          current: page,
          onChange: (newPage) => setPage(newPage),
          position: ['bottomCenter'],
        }}
      />

      {/* <Drawer title={drawerData?.name || 'Video Info'} placement="right" size="large" onClose={onClose} open={open}>
        
        {drawerData?.actingVideoList && (
          <div style={{ marginBottom: 20 }}>
            <Typography.Title level={4}>Acting Videos</Typography.Title>
            <Table
              dataSource={drawerData.actingVideoList}
              pagination={false}
              rowKey={(record) => record.id}
              columns={[
                {
                  title: ' ID',
                  dataIndex: 'id',
                  key: 'id',
                },
                {
                  title: 'Name',
                  dataIndex: 'name',
                  key: 'name',
                },
                {
                  key: 'gender',
                  title: '성별',
                  dataIndex: 'gender',
                  width: '10%',
                  render: (t, r) => (
                    <img src={r.gender === 'FEMALE' ? FemaleAvatar : MaleAvatar} width="40" alt={r.gender} />
                  ),
                },
                {
                  key: 'video',
                  title: 'Video',
                  dataIndex: 'video',
                  render: (t, r) => (
                    <a href={r.video} target="_blank" rel="noopener noreferrer">
                      {r.video}
                    </a>
                  ),
                },
              ]}
            />
          </div>
        )}
      </Drawer> */}
    </div>
  )
}

export default withTitleMetaData(Models)
