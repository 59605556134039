import React from 'react'
import { Layout, theme, Row, Col, Menu, Space, Button } from 'antd'
import { QuestionCircleFilled, BellFilled, UserOutlined } from '@ant-design/icons'
import { useNavigate, Link } from 'react-router-dom'
import { useAuthState } from '../../atoms/auth'
import MyMenu from './MyMenu'

const { Header } = Layout

function getItem(label, key, icon) {
  return {
    label,
    key,
    icon,
  }
}
const gnbItems = [
  getItem(<Link to="/example">Items 1</Link>, 'gnb:3', <BellFilled />),
  getItem(<Link to="/example">Items 2</Link>, 'gnb:4', <QuestionCircleFilled />),
]

function HeaderComponent() {
  const navigate = useNavigate()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [authState, setAuthState] = useAuthState()
  const logoutHandler = () => {
    // localStorage.removeItem("auth");
    localStorage.removeItem('token')
    setAuthState({ loggedIn: false, email: '', password: '' })
    navigate('/login')
  }

  const {
    token: { colorBgContainer },
  } = theme.useToken()
  return (
    <Header
      style={{
        padding: 0,
        background: colorBgContainer,
      }}
    >
      <Row align="middle">
        <Col flex="auto">
          <Menu theme="light" mode="horizontal" defaultSelectedKeys={['']} items={gnbItems} />
        </Col>
        <Col align="right" flex="40%">
          <Space size="small" align="center" style={{ paddingInlineEnd: '16px' }}>
            <Button icon={<QuestionCircleFilled />} />
            <Button icon={<BellFilled />} />
            <MyMenu logoutHandler={logoutHandler}>
              <Button
                shape="circle"
                icon={<UserOutlined size="32" style={{ verticalAlign: 'middle', marginTop: '-4px' }} />}
                style={{ padding: '0 0', border: 0, boxShadow: 'none' }}
              />
            </MyMenu>
          </Space>
        </Col>
      </Row>
    </Header>
  )
}
export default HeaderComponent
