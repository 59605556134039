import apiClient from '../service/api'

// GET All voices
export const getAllVoices = async (page) => {
  const token = localStorage.getItem('token')

  const { data } = await apiClient.get(`/v1/resources/voices?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return data
}

// Get All Aivatars
export const getAllAivatars = async (page) => {
  const token = localStorage.getItem('token')

  const { data } = await apiClient.get(`/v1/resources/aivatars?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return data
}

// Get Acting Videos
export const getActingVideos = async (page) => {
  const token = localStorage.getItem('token')
  const { data } = await apiClient.get(`/v1/resources/actingvideos?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return data
}

// Get Acting Video
export const getActingVideo = async (videoId) => {
  const token = localStorage.getItem('token')
  const { data } = await apiClient.get(`/v1/resources/actingvideos/${videoId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return data
}

// Get Deep running Models
export const getModels = async (page) => {
  const token = localStorage.getItem('token')
  const { data } = await apiClient.get(`/v1/resources/models?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return data
}

// Get Deep running Model
export const getModel = async (modelId) => {
  const token = localStorage.getItem('token')

  const { data } = await apiClient.get(`/v1/resources/models/${modelId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return data
}
