import React from 'react'
import { useRoutes } from 'react-router-dom'
import { Dashboard, Login, Notfound } from './pages'
import { RegisterUser, Users } from './pages/User'
import { Workspaces } from './pages/Workspaces'
import { Groups } from './pages/Group'
import { CreateProject, Project, Projects } from './pages/Project'
import { Videos } from './pages/Video'
import { Audios } from './pages/Audio'
import { CreatePlan, Plans } from './pages/Plan'
import LayoutProvider from './Layout'
import { Aivatars, CreateAivatar, CreateVoice, Voices } from './pages/Resources'
import { ActingVideo, ActingVideos, CreateActingVideo } from './pages/Resources/ActingVideo'
import { CreateModels, Model, Models, UpdateModels } from './pages/Resources/Model'
import User from './pages/User/User'
import WorkspaceContainer from './pages/Workspaces/Workspace/WorkspaceContainer'

const AppRoutes = () => {
  const element = useRoutes([
    {
      path: '/',
      element: <LayoutProvider />,
      children: [
        { path: '/', element: <Dashboard title="Dashboard" /> },
        { path: '/dashboard', element: <Dashboard title="Dashboard" /> },
        { path: '/login', element: <Login /> },
        { path: '/user', element: <Users title="회원 목록" /> },
        { path: '/user/register', element: <RegisterUser title="회원 등록" /> },
        { path: '/user/:userId', element: <User title="회원 상세" /> },
        {
          path: '/workspace',
          element: <Workspaces title="워크스페이스 목록" />,
        },
        {
          path: '/workspace/:workspaceId',
          element: <WorkspaceContainer title="워크스페이스 상세" />,
        },
        { path: '/group', element: <Groups title="조직 목록" /> },
        { path: '/project', element: <Projects title="프로젝트 목록" /> },
        { path: '/project/:projectId', element: <Project title="프로젝트 상세" /> },
        {
          path: '/project/create',
          element: <CreateProject title="프로젝트 생성" />,
        },
        { path: '/video', element: <Videos title="비디오 목록" /> },
        { path: '/audio', element: <Audios title="오디오 목록" /> },
        { path: '/plan', element: <Plans title="플랜 목록" /> },
        { path: '/plan/create', element: <CreatePlan title="플랜 생성" /> },
        { path: 'resources/voices', element: <Voices title="목소리 목록" /> },
        { path: 'resources/total', element: <Voices title="통합관리" /> },
        {
          path: 'resources/voices/create',
          element: <CreateVoice title="목소리 등록" />,
        },
        {
          path: 'resources/videos',
          element: <ActingVideos title="행동영상 목록" />,
        },
        {
          path: 'resources/videos/:videoId',
          element: <ActingVideo title="행동영상 상세" />,
        },
        {
          path: 'resources/videos/create',
          element: <CreateActingVideo title="행동영상 등록" />,
        },
        {
          path: 'resources/models',
          element: <Models title="딥러닝모델 목록" />,
        },
        {
          path: 'resources/models/create',
          element: <CreateModels title="딥러닝모델 등록" />,
        },
        {
          path: 'resources/models/update/:modelId',
          element: <UpdateModels title="딥러닝모델 수정" />,
        },
        {
          path: 'resources/models/:modelId',
          element: <Model title="딥러닝모델 상세" />,
        },
        {
          path: 'resources/aivatars',
          element: <Aivatars title="아이바타 목록" />,
        },
        {
          path: 'resources/aivatars/create',
          element: <CreateAivatar title="아이바타 등록" />,
        },
        { path: '/*', element: <Notfound /> },
      ],
    },
  ])

  return element
}

export default AppRoutes
