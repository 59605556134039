import React, { useCallback, useEffect } from 'react'
import { Button, Dropdown, Space, theme } from 'antd'
import { useAuthState } from '../../atoms/auth'
import { useUsersState } from '../../atoms/users'
import { useUserActions } from '../../Actions/userApi'

const { useToken } = theme

function MyMenu({ children, logoutHandler }) {
  // const userInfo = useRecoilValue(usersAtom);
  const [authState, setAuthState] = useAuthState()
  const [userState, setUserState] = useUsersState()
  const userActions = useUserActions()

  const items = [
    {
      key: '1',
      label: <p>{userState && userState.name}</p>,
    },
    {
      key: '2',
      label: <p>{userState && userState.email}</p>,
      disabled: false,
    },
  ]

  const setUserInfo = useCallback(async () => {
    try {
      const userInfo = await userActions.getUserInfo()
      setAuthState({ loggedIn: true })
      setUserState(userInfo)
      console.log('USERINFO::', userInfo)
      console.log('MY_AUTH_STATE', authState.loggedIn)
    } catch (error) {
      console.log(error)
    }
  }, [authState.loggedIn, setAuthState, setUserState, userActions])

  const { token } = useToken()
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  }
  const menuStyle = {
    boxShadow: 'none',
  }

  useEffect(() => {
    setUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dropdown
      menu={{
        items,
      }}
      trigger={['click']}
      // eslint-disable-next-line react/no-unstable-nested-components
      dropdownRender={(menu) => (
        <div style={contentStyle}>
          {React.cloneElement(menu, {
            style: menuStyle,
          })}
          <Space
            style={{
              padding: 8,
            }}
          >
            <Button danger onClick={logoutHandler}>
              Log out
            </Button>
          </Space>
        </div>
      )}
    >
      {children}
    </Dropdown>
  )
}
export default MyMenu
