import React from 'react'
import { Row, Col } from 'antd'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Users from './User/Users'
import { MembersWidget, ProjectsWidget, PlansWidget, WorkspacesWidget } from '../components/Dashboard'
import withTitleMetaData from '../hocs/withTitleMetaData'

function Dashboard() {
  // Dashboard.propTypes = {
  //   title: PropTypes.string,
  // }

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <WorkspacesWidget />
        </Col>
        <Col span={8}>
          <MembersWidget />
        </Col>
        <Col span={8}>
          <ProjectsWidget />
        </Col>
        <Col span={8}>
          <PlansWidget />
        </Col>
      </Row>
    </div>
  )
}
export default withTitleMetaData(Dashboard)
