import React, { useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import AppRoutes from './routes'
import { useAuthState } from './atoms/auth'
import Login from './pages/Login'

function App() {
  const [authState, setAuthState] = useAuthState()

  useEffect(() => {
    // const token = JSON.parse(localStorage.getItem("auth"));
    const token = localStorage.getItem('token')
    if (token) {
      console.log('TOKEN:::', token)
      setAuthState({ loggedIn: true, ...token })
    }
  }, [setAuthState])

  return <HelmetProvider>{authState.loggedIn ? <AppRoutes /> : <Login />}</HelmetProvider>
}

export default App
