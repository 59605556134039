import React, { useEffect, useState } from 'react'
import { Table, Tag, Typography } from 'antd'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment/moment'
import { NumberParam, useQueryParam } from 'use-query-params'
import { getAllVideos } from '../../Actions/videosApi'
import withTitleMetaData from '../../hocs/withTitleMetaData'

const { Text } = Typography

function Videos() {
  // Videos.propTypes = {
  //   title: PropTypes.string,
  // }

  const [posts, setPosts] = useState([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageable, setPageable] = useState({})
  const [totalElements, setTotalElements] = useState(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalPages, setTotalPages] = useState(null)
  const [page, setPage] = useQueryParam('page', NumberParam)

  const { isLoading: isLoadingQuery, data } = useQuery({
    queryKey: ['getAllVideos', page],
    queryFn: () => getAllVideos(page ? page - 1 : 0),
    staleTime: 500,
    keepPreviousData: false,
    onError: (e) => {
      // API 연결이 실패한 경우에 호출됨
      console.log(e.message)
    },
  })

  const isLoading = isLoadingQuery

  // const queryClient = useQueryClient()

  // useEffect(() => {
  //   const nextPage = currentPage + 1
  //   queryClient.prefetchQuery(['getAllVideos', nextPage], () => getAllVoices(nextPage - 1)) // 다음 페이지의 데이터를 미리 fetch
  // }, [currentPage, queryClient])

  const getTagColor = (status) => {
    switch (status) {
      case 'READY':
        return 'yellow'
      case 'FAIL':
        return 'red'
      case 'COMPLETED':
        return 'green'
      case 'PROCESSING':
        return 'orange'
      default:
        return 'gray'
    }
  }

  const columns = [
    {
      key: 'movieId',
      title: 'Id',
      dataIndex: 'movieId',
    },
    {
      key: 'actingVideoId',
      title: 'Acting Video',
      dataIndex: 'actingVideoId',
    },
    {
      key: 'deepLearningModelId',
      title: 'Model',
      dataIndex: 'deepLearningModelId',
    },
    {
      key: 'rgba',
      title: '배경 색상',
      dataIndex: 'rgba',
      width: '10%',
      render: (rgba) => (rgba !== null ? rgba : 'null'),
    },
    {
      key: 'filename',
      title: 'File Name',
      dataIndex: 'filename',
      width: '10%',
    },
    {
      key: 'filetype',
      title: 'File Type',
      dataIndex: 'filetype',
      width: '10%',
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (status) => {
        const color = getTagColor(status)
        return <Tag color={color}>{status || 'N/A'}</Tag>
      },
    },
    {
      key: 'createdAt',
      title: 'Creation date',
      dataIndex: 'createdAt',
      render: (t, r) => <Text type="secondary">{moment(r.createdAt).format('YYYY-MM-DD HH:mm')}</Text>,
      width: '20%',
    },
  ]

  useEffect(() => {
    if (data) {
      setPosts(data.content)
      setPageable(data.pageable)
      setTotalElements(data.totalElements)
      setTotalPages(data.totalPages - 1)
    }
  }, [data])

  return (
    <div>
      <Table
        columns={columns}
        dataSource={posts}
        rowKey={(record) => record.movieId}
        loading={isLoading}
        pagination={{
          total: totalElements,
          current: page,
          onChange: (newPage) => setPage(newPage),
          position: ['bottomCenter'],
        }}
      />
    </div>
  )
}

export default withTitleMetaData(Videos)
