import React from 'react'
import withTitleMetaData from '../../hocs/withTitleMetaData'

function CreateProject() {
  // CreateProject.propTypes = {
  //   title: PropTypes.string,
  // }

  return (
    <div>
      <span>프로젝트 생성</span>
    </div>
  )
}
export default withTitleMetaData(CreateProject)
