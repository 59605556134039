import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Layout, theme } from 'antd'
import { Aside, HeaderComponent, FooterComponent } from './components/Common'

const { Content } = Layout

function LayoutProvider() {
  const [layoutMargin, setLayoutMargin] = useState(200)
  const changeLayoutMargin = (value) => (value !== layoutMargin ? setLayoutMargin(value) : setLayoutMargin(200))
  const {
    token: { colorBgContainer },
  } = theme.useToken()

  useEffect(() => {
    setLayoutMargin(200)
  }, [])

  return (
    <Layout hasSider>
      <Aside changeLayoutMargin={changeLayoutMargin} />
      <Layout
        className="site-layout"
        style={{
          marginLeft: layoutMargin,
        }}
      >
        <HeaderComponent />
        <Content
          style={{
            margin: '24px 16px 0',
            overflow: 'initial',
          }}
        >
          <div
            style={{
              borderRadius: 8,
              padding: 16,
              minHeight: 'calc(100vh - 204px)',
              background: colorBgContainer,
            }}
          >
            <Outlet />
          </div>
        </Content>
        <FooterComponent />
      </Layout>
    </Layout>
  )
}
export default LayoutProvider
