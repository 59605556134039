// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { MenuFoldOutlined } from '@ant-design/icons'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getSubscriptions, getWorkspaces } from '../../../Actions'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import FemaleAvatar from '../../../assets/images/female_avatar.png'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import MaleAvatar from '../../../assets/images/male_avatar.png'
import WorkspaceDetails from './components/WorkspaceDetails'
import WorkspaceCredits from './components/WorkspaceCredits'
// import WorkspacePlan from './components/WorkspacePlan'

type Props = {
  workspaceId: number
  brief?: boolean
}

function Workspace({ workspaceId, brief }: Props) {
  // Workspace.propTypes = {
  //   title: PropTypes.string,
  // }

  // const { data: details } = useQuery({
  //   ...workspaceQueries.detail(workspaceId),
  //   enabled: !!workspaceId,
  // })
  // const { data: credits, refetch: refetchCredits } = useQuery({
  //   ...workspaceQueries.detail(workspaceId)._ctx.credits,
  //   enabled: !!workspaceId,
  // })

  return (
    <div>
      <WorkspaceDetails workspaceId={workspaceId} />

      {/* {!brief ? <WorkspacePlan workspaceId={workspaceId} /> : null} */}

      {!brief ? <WorkspaceCredits workspaceId={workspaceId} /> : null}

      {/* {!brief ? <SubscriptionsHistory workspaceId={workspaceId} /> : null} */}
    </div>
  )
}
export default Workspace
