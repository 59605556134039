import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { Button, Card, Col, Descriptions, Drawer, Modal, Row, Table, Tag, Typography } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import { loadAudio } from '../../Actions'
import { getProject } from '../../Actions/projectsApi'
import withTitleMetaData from '../../hocs/withTitleMetaData'

const { Title } = Typography

function Project() {
  const { projectId } = useParams()
  const [projectData, setProjectData] = useState({})
  const [audioSrc, setAudioSrc] = useState(null)

  const [open, setOpen] = useState(false)
  const [drawerData, setDrawerData] = useState({})
  const [isModalVisible, setIsModalVisible] = useState(false)

  // console.log("PARAMS:::", projectId);

  const { isLoading, data } = useQuery({
    queryKey: ['getProject', projectId],
    queryFn: () => getProject(projectId),
    staleTime: 500,
    onError: (e) => {
      // API 연결이 실패한 경우에 호출됨
      console.log(e.message)
    },
  })

  useEffect(() => {
    if (data) {
      setProjectData(data)
      console.log(data)
    }
  }, [data])

  const showDrawer = (r) => {
    setDrawerData(r)
    setOpen(true)
  }

  const closeDrawer = () => {
    setOpen(false)
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const getTagColor = (status) => {
    switch (status) {
      case 'READY':
        return 'yellow'
      case 'FAIL':
        return 'red'
      case 'COMPLETED':
        return 'green'
      case 'PROCESSING':
        return 'orange'
      default:
        return 'gray'
    } /**/
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
      render: (t, r) => (
        <Button type="link" onClick={() => showDrawer(r)}>
          {t}
        </Button>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'attribute',
      key: 'status',
      render: (attribute) => {
        const status = attribute ? attribute.status : 'N/A'
        const color = getTagColor(status)
        return <Tag color={color}>{status}</Tag>
      },
    },
    {
      title: 'Language',
      dataIndex: 'language',
      key: 'language',
    },
  ]

  useEffect(() => {
    const fetchAudio = async () => {
      if (drawerData.attribute) {
        const audioURL = await loadAudio(drawerData.attribute.audioId)
        setAudioSrc(audioURL)
      }
    }

    fetchAudio()
  }, [drawerData])

  return (
    <div>
      <Row align="middle">
        <Col flex="auto">
          <Title level={3}>{projectData?.title}</Title>
        </Col>
        <Col>
          <Button size="large" onClick={showModal}>
            full contents
          </Button>
        </Col>
      </Row>

      {projectData && (
        <>
          <Table
            columns={columns}
            dataSource={projectData.sentences}
            rowKey={(record) => record.id}
            loading={isLoading}
          />
          <Modal title="full contents" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <Paragraph>{projectData.str}</Paragraph>
          </Modal>
        </>
      )}
      <Drawer title={`Attribute - ${drawerData?.id}`} open={open} onClose={closeDrawer} width={720}>
        {/* Attribute */}
        {drawerData?.attribute && (
          <div style={{ marginBottom: 20 }}>
            <Typography.Title level={4}>Audio Attribute</Typography.Title>
            <Descriptions column={2} bordered>
              <Descriptions.Item label="Audio ID">{drawerData.attribute.audioId}</Descriptions.Item>
              <Descriptions.Item label="Voice ID">{drawerData.attribute.voiceId}</Descriptions.Item>
              <Descriptions.Item label="Voice Name">{drawerData.attribute.voiceDisplayName}</Descriptions.Item>
              <Descriptions.Item label="Space">{drawerData.attribute.space}</Descriptions.Item>
              <Descriptions.Item label="Pitch">{drawerData.attribute.pitch}</Descriptions.Item>
              <Descriptions.Item label="Speed">{drawerData.attribute.speed}</Descriptions.Item>
              <Descriptions.Item label="Volume">{drawerData.attribute.volume}</Descriptions.Item>
              <Descriptions.Item label="Language">{drawerData.attribute.language}</Descriptions.Item>
              <Descriptions.Item label="Status">{drawerData.attribute.status}</Descriptions.Item>
              <Descriptions.Item label="Duration">{drawerData.attribute.duration}</Descriptions.Item>
              <Descriptions.Item label="Engine Code">{drawerData.attribute.engineCode}</Descriptions.Item>
              <Descriptions.Item label="Engine ID">{drawerData.attribute.engineId}</Descriptions.Item>
            </Descriptions>
          </div>
        )}

        {/* Text & Listen */}
        {drawerData?.attribute && (
          <div style={{ marginBottom: 20 }}>
            <Typography.Title level={4}>Text & Listen</Typography.Title>
            <Card bordered style={{ backgroundColor: '#f5f5f5' }}>
              <Typography.Paragraph>
                {drawerData.attribute.text} {/* 텍스트 내용을 이곳에 입력하세요 */}
              </Typography.Paragraph>
            </Card>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio controls style={{ marginTop: 20 }}>
              <source src={audioSrc} type="audio/mpeg" />
            </audio>
          </div>
        )}
      </Drawer>
    </div>
  )
}
export default withTitleMetaData(Project)
