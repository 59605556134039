import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Card, Button, List, Tooltip, Typography } from 'antd'
import { ArrowRightOutlined, SyncOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { getUsers } from '../../Actions/userApi'

const { Text } = Typography

function MembersWidget() {
  const [posts, setPosts] = useState([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [page, setPage] = useState(0)
  const {
    isLoading,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    error,
    refetch,
    data: users,
  } = useQuery({
    queryKey: ['getUsers', page],
    queryFn: () => getUsers(page),
    onError: () => {
      console.log(error)
    },
  })

  useEffect(() => {
    if (users) {
      setPosts(users.content)
    }
  }, [users])

  return (
    <Card
      title={
        <Link to="/user">
          회원 <ArrowRightOutlined />
        </Link>
      }
      headStyle={{ paddingInline: '16px' }}
      bodyStyle={{ padding: 0 }}
      loading={isLoading}
      extra={
        <Tooltip title="새로고침">
          <Button shape="circle" size="small" onClick={refetch} icon={<SyncOutlined />} loading={isLoading} />
        </Tooltip>
      }
    >
      <List itemLayout="vertical" size="small">
        {posts &&
          posts
            .map((post) => (
              <List.Item
                key={`${post.createdAt}-${Math.floor(Math.random() * 100)}${1}`}
                extra={<Text type="secondary">{post.createdAt.slice(0, 10)}</Text>}
              >
                {post.name}
              </List.Item>
            ))
            .slice(0, 5)}
      </List>
    </Card>
  )
}
export default MembersWidget
