import apiClient from '../service/api'

// GET Projects
export const getProjects = async (page) => {
  const token = localStorage.getItem('token')
  console.log('토큰 맞아?', token)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const { data } = await apiClient.get(`/v1/projects?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  // console.log("getProjects", response);

  return data
}

// GET Project
export const getProject = async (projectId) => {
  const token = localStorage.getItem('token')

  const { data } = await apiClient.get(`/v1/projects/${projectId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  // console.log("getProject", data);

  return data
}

// CREATE Project
// export const createProject = async () => {
//   const token = localStorage.getItem('token')

//   const { data } = await apiClient.post(``)
// }
