import { useRecoilValue } from 'recoil'
import apiClient from '../service/api'
// eslint-disable-next-line import/no-cycle
import { currentWorkspace, loginState } from '../atoms'

const ClientApi = {
  /** dummy test code */
  asyncTest: async (success, timeout, dummy = {}) =>
    new Promise((resolve, reject) => {
      console.log(success)

      setTimeout(() => {
        if (success) {
          resolve(dummy)
        }
        reject(dummy)
      }, timeout || 700)
    }),

  resendVerifyEmail: async (userId) =>
    new Promise((resolve, reject) => {
      try {
        const response = apiClient.get(`/v1/auth/resend-verification?id=${userId}`)
        resolve(response)
      } catch (e) {
        reject(e)
      }
    }),

  project: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    get: async (id) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const url = '/v1/projects/'
      return new Promise((resolve, reject) => {
        try {
          // let response = apiClient.get(`api/v1/projects${id ? id : ''}`);
          // resolve(response)
        } catch (e) {
          reject(e)
        }
      })
    },
    list: async () => {
      // workspace: useRecoilValue(loginState)
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const workspace = useRecoilValue(currentWorkspace)
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const login = useRecoilValue(loginState)
      return new Promise((resolve, reject) => {
        const url = `/v1/workspaces/${workspace.id}/projects`
        try {
          console.log(`Bearer ${login.accessToken}`)
          const response = apiClient.get(url, {
            headers: { Authorization: `Bearer ${login.accessToken}` },
          })
          resolve(response)
        } catch (e) {
          // eslint-disable-next-line no-promise-executor-return
          reject(e)
        }
      })
    },
    create: async (body) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const workspace = useRecoilValue(currentWorkspace)
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const login = useRecoilValue(loginState)

      return new Promise((resolve, reject) => {
        const url = `/v1/workspaces/${workspace.id}/projects`
        const config = {
          headers: { Authorization: `Bearer ${login.accessToken}` },
        }

        try {
          const response = apiClient.post(url, body, config)
          resolve(response)
        } catch (e) {
          reject(e)
        }
      })
    },
  },
}

// eslint-disable-next-line import/prefer-default-export
export { ClientApi }
