import React, { useEffect, useRef, useState } from 'react'
import { Typography, Button, Form, Input, Select, Modal, message, Col, Row } from 'antd'
import { Link } from 'react-router-dom'
import useInput from '../../../hooks/useInput'
import apiClient from '../../../service/api'
import withTitleMetaData from '../../../hocs/withTitleMetaData'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { Title, Text } = Typography
const { Option } = Select

const layout = {
  labelCol: {
    span: 0,
  },
  wrapperCol: {
    span: 24,
  },
}

const formItemLayout = {
  labelCol: {
    xs: {
      span: 8,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 32,
    },
    sm: {
      span: 32,
    },
  },
}

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} 은 필수 항목 입니다.',
  types: {
    name: '${label} 을 입력해 주세요.',
    modelType: '${label} 을 입력해 주세요.',
  },
}
/* eslint-enable no-template-curly-in-string */

function CreateModels() {
  // CreateModels.propTypes = {
  //   title: PropTypes.string,
  // }

  const [name, onChangeName] = useInput('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modelType, onChangeModelType] = useInput('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [weightPath, onChangeWeightPath] = useInput('')
  const [faceType, onChangeFaceType] = useInput('')
  const [imageSize, onChangeImageSize] = useInput('')
  const [mode, onChangeMode] = useInput('')
  const [maskMode, onChangeMaskMode] = useInput('')
  const [erodeMaskModifier, onChangeErodeMaskModifier] = useInput(0)
  const [blurMaskModifier, onChangeBlurMaskModifier] = useInput(0)
  const [motionBlurPower, onChangeMotionBlurPower] = useInput(0)
  const [outputFaceScale, onChangeOutputFaceScale] = useInput(0)
  const [colorTransferMode, onChangeColorTransferMode] = useInput('')
  const [sharpenMode, onChangeSharpenMode] = useInput('')
  const [blursharpenAmount, onChangeBlursharpenAmount] = useInput(0)
  const [superResolutionPower, onChangeSuperResolutionPower] = useInput(0)
  const [imageDenoisePower, onChangeImageDenoisePower] = useInput(0)
  const [bicubicDegradePower, onChangeBicubicDegradePower] = useInput(0)
  const [colorDegradePower, onChangeColorDegradePower] = useInput(0)

  const [form] = Form.useForm()
  const formRef = useRef(null)

  const [uploading, setUploading] = useState(false)
  const [disabled, setDisabled] = useState(true)

  const [messageApi, contextHolder] = message.useMessage()

  /**
   * API 에러 메시지 UI
   * @param msg
   */
  const errorMsg = (msg) => {
    messageApi.open({
      type: 'error',
      content: msg,
    })
  }

  const onFinish = (values) => {
    const token = localStorage.getItem('token')

    setUploading(true)
    apiClient
      .post('/v1/resources/models', values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          Modal.success({
            title: '모델 생성이 정상적으로 되었습니다.',
          })
          setUploading(false)
          formRef.current?.resetFields()
          setDisabled(true)
        }
      })
      .catch((e) => {
        console.error(e)
        if (e) {
          errorMsg(e.message)
        }
        setUploading(false)
      })
      .finally(() => {
        setUploading(false)
        form.resetFields()
        setDisabled(true)
      })
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  useEffect(() => {
    if (name) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [name])

  return (
    <div>
      {contextHolder}

      <Form
        {...formItemLayout}
        layout="horizontal"
        form={form}
        ref={formRef}
        name="register-model"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{
          maxWidth: 480,
          margin: '0 auto 0 128px',
        }}
        scrollToFirstError
        validateMessages={validateMessages}
      >
        <Form.Item
          name="name"
          label="Model Name"
          rules={[
            {
              type: 'name',
              required: true,
            },
          ]}
        >
          <Input
            type="text"
            placeholder="DFModel - contents.xlsx: PCM"
            value={name}
            onChange={onChangeName}
            allowClear
          />
        </Form.Item>

        <Form.Item
          name="modelType"
          label="Model Type"
          rules={[
            {
              type: 'modelType',
              required: true,
            },
          ]}
        >
          <Select placeholder="Model - contents.xlsx: DForSR" allowClear>
            <Option value="DF">DF</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="faceType"
          label="faceType"
          rules={[
            {
              type: 'faceType',
              required: true,
            },
          ]}
        >
          <Input type="text" value={faceType} onChange={onChangeFaceType} placeholder="faceType" allowClear />
        </Form.Item>

        <Form.Item
          name="imageSize"
          label="imageSize"
          rules={[
            {
              type: 'imageSize',
              required: true,
            },
          ]}
        >
          <Input type="number" value={imageSize} onChange={onChangeImageSize} placeholder="imageSize" allowClear />
        </Form.Item>

        <Form.Item
          name="mode"
          label="mode"
          rules={[
            {
              type: 'mode',
              required: true,
            },
          ]}
        >
          <Input type="text" value={mode} onChange={onChangeMode} placeholder="mode" allowClear />
        </Form.Item>

        <Form.Item
          name="maskMode"
          label="maskMode"
          rules={[
            {
              type: 'maskMode',
              required: true,
            },
          ]}
        >
          <Input type="text" value={maskMode} onChange={onChangeMaskMode} placeholder="maskMode" allowClear />
        </Form.Item>

        <Form.Item
          name="erodeMaskModifier"
          label="erodeMaskModifier"
          rules={[
            {
              type: 'erodeMaskModifier',
              required: true,
            },
          ]}
        >
          <Input
            type="number"
            value={erodeMaskModifier}
            onChange={onChangeErodeMaskModifier}
            placeholder="erodeMaskModifier"
            allowClear
          />
        </Form.Item>

        <Form.Item
          name="blurMaskModifier"
          label="blurMaskModifier"
          rules={[
            {
              type: 'blurMaskModifier',
              required: true,
            },
          ]}
        >
          <Input
            type="number"
            value={blurMaskModifier}
            onChange={onChangeBlurMaskModifier}
            placeholder="blurMaskModifier"
            allowClear
          />
        </Form.Item>

        <Form.Item
          name="motionBlurPower"
          label="motionBlurPower"
          rules={[
            {
              type: 'motionBlurPower',
              required: true,
            },
          ]}
        >
          <Input
            type="number"
            value={motionBlurPower}
            onChange={onChangeMotionBlurPower}
            placeholder="motionBlurPower"
            allowClear
          />
        </Form.Item>

        <Form.Item
          name="outputFaceScale"
          label="outputFaceScale"
          rules={[
            {
              type: 'outputFaceScale',
              required: true,
            },
          ]}
        >
          <Input
            type="number"
            value={outputFaceScale}
            onChange={onChangeOutputFaceScale}
            placeholder="outputFaceScale"
            allowClear
          />
        </Form.Item>

        <Form.Item
          name="colorTransferMode"
          label="colorTransferMode"
          rules={[
            {
              type: 'colorTransferMode',
              required: true,
            },
          ]}
        >
          <Input
            type="text"
            value={colorTransferMode}
            onChange={onChangeColorTransferMode}
            placeholder="colorTransferMode"
            allowClear
          />
        </Form.Item>

        <Form.Item
          name="sharpenMode"
          label="sharpenMode"
          rules={[
            {
              type: 'sharpenMode',
              required: true,
            },
          ]}
        >
          <Input type="text" value={sharpenMode} onChange={onChangeSharpenMode} placeholder="sharpenMode" allowClear />
        </Form.Item>

        <Form.Item
          name="blursharpenAmount"
          label="blursharpenAmount"
          rules={[
            {
              type: 'blursharpenAmount',
              required: true,
            },
          ]}
        >
          <Input
            type="number"
            value={blursharpenAmount}
            onChange={onChangeBlursharpenAmount}
            placeholder="blursharpenAmount"
            allowClear
          />
        </Form.Item>

        <Form.Item
          name="superResolutionPower"
          label="superResolutionPower"
          rules={[
            {
              type: 'superResolutionPower',
              required: true,
            },
          ]}
        >
          <Input
            type="number"
            value={superResolutionPower}
            onChange={onChangeSuperResolutionPower}
            placeholder="superResolutionPower"
            allowClear
          />
        </Form.Item>

        <Form.Item
          name="imageDenoisePower"
          label="imageDenoisePower"
          rules={[
            {
              type: 'imageDenoisePower',
              required: true,
            },
          ]}
        >
          <Input
            type="number"
            value={imageDenoisePower}
            onChange={onChangeImageDenoisePower}
            placeholder="imageDenoisePower"
            allowClear
          />
        </Form.Item>

        <Form.Item
          name="bicubicDegradePower"
          label="bicubicDegradePower"
          rules={[
            {
              type: 'bicubicDegradePower',
              required: true,
            },
          ]}
        >
          <Input
            type="number"
            value={bicubicDegradePower}
            onChange={onChangeBicubicDegradePower}
            placeholder="bicubicDegradePower"
            allowClear
          />
        </Form.Item>

        <Form.Item
          name="colorDegradePower"
          label="colorDegradePower"
          rules={[
            {
              type: 'colorDegradePower',
              required: true,
            },
          ]}
        >
          <Input
            type="number"
            value={colorDegradePower}
            onChange={onChangeColorDegradePower}
            placeholder="colorDegradePower"
            allowClear
          />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            ...layout.wrapperCol,
            offset: 0,
          }}
        >
          <Button type="primary" htmlType="submit" disabled={disabled} loading={uploading} block>
            {uploading ? 'Process...' : 'Create'}
          </Button>
        </Form.Item>

        <Row justify="end">
          <Col>
            <Link to="/resources/models" style={{ fontSize: '16px' }}>
              List
            </Link>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default withTitleMetaData(CreateModels)
