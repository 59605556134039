import apiClient from '../service/api'

/**
 * 조직 목록 API
 * @returns {Promise<*>}
 */
export const getGroups = async () => {
  const { data } = await apiClient.get(`/groups`)

  return data
}

export const dummy = null
