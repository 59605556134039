import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, message, Modal, Row, Select, Upload } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'
import apiClient from '../../../service/api'
import useInput from '../../../hooks/useInput'
import withTitleMetaData from '../../../hocs/withTitleMetaData'

const { Option } = Select

const layout = {
  labelCol: {
    span: 0,
  },
  wrapperCol: {
    span: 24,
  },
}

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} 은 필수 항목 입니다.',
  types: {
    name: '${label} 을 입력해 주세요.',
    displayName: '${label} 을 입력해 주세요.',
    file: '${label} 를 등록해 주세요.',
    language: '${label} 를 등록해 주세요.',
    gender: '${label} 를 등록해 주세요.',
  },
}
/* eslint-enable no-template-curly-in-string */

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
}

function CreateVoice() {
  // CreateVoice.propTypes = {
  //   title: PropTypes.string,
  // }
  const [name, onChangeName] = useInput('')
  const [displayName, onChangeDisplayName] = useInput('')
  const [tag, onChangeTag] = useInput('')
  const [engineCode, onChangeEngineCode] = useInput('')
  const [level, onChangeLevel] = useInput(0)
  const navigate = useNavigate()
  const [locales, setLocales] = useState([])
  const [coef, setCoef] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [intercept, setIntercept] = useState('')
  const token = localStorage.getItem('token')

  const [form] = Form.useForm()

  // Form onFinish

  const [fileList, setFileList] = useState([])
  const [uploading, setUploading] = useState(false)

  const [messageApi, contextHolder] = message.useMessage()

  /**
   * API 에러 메시지 UI
   * @param msg
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const errorMsg = (msg) => {
    messageApi.open({
      type: 'error',
      content: msg,
    })
  }

  useEffect(() => {
    apiClient
      .get('/v1/resources/locales', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setLocales(res.data))
      .catch((err) => console.error('Error fetching locales:', err))
  }, [token])

  const languageOptions = locales.map((locale) => ({
    id: locale.id,
    language: locale.language,
  }))

  const fileProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file])
      return false
    },
    action: (file) => {
      console.log(file)
    },
    fileList,
  }

  const getFile = (e) => {
    console.log('Upload event:', e)
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  // 방법4
  const onFinish = (values) => {
    const formData = new FormData()

    Object.entries(values).forEach(([fieldName, fieldValue]) => {
      if (fieldName === 'file') {
        return
      }
      formData.append(fieldName, fieldValue)
    })

    // todo: upload file이 없으면!
    formData.append('file', values.file[0].originFileObj)
    // adding path
    formData.append('samplePath', values.file[0].name)
    setUploading(true)
    apiClient
      .post('/v1/resources/voices', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => {
        console.log(data)
        setFileList([])
        message.success('upload successfully.')
        navigate('/resources/voices')
      })
      .catch(() => {
        message.error('upload failed.')
      })
      .finally(() => {
        setUploading(false)
        form.resetFields()
      })
  }

  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  const handleCancel = () => setPreviewOpen(false)
  const handlePreview = async (previewFile) => {
    const file = previewFile
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewOpen(true)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  }
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList)
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  )

  return (
    <div>
      {contextHolder}

      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>

      <Form
        {...formItemLayout}
        layout="vertical"
        form={form}
        encType="multipart/form-data"
        name="register-voice"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{
          maxWidth: 360,
          margin: '0 auto',
        }}
        scrollToFirstError
        validateMessages={validateMessages}
      >
        <Form.Item name="file" label="목소리 파일 첨부(Audio file)" getValueFromEvent={getFile}>
          <Upload
            {...fileProps}
            name="file"
            maxCount={1}
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            accept="audio/*"
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item
          name="name"
          label="Voice Name"
          tooltip="보이스 네임"
          rules={[
            {
              type: 'name',
              required: true,
            },
          ]}
        >
          <Input type="text" placeholder="보이스 네임" value={name} onChange={onChangeName} allowClear />
        </Form.Item>
        <Form.Item
          name="displayName"
          label="Display Name"
          tooltip="사용자에게 보여지는 이름"
          rules={[
            {
              type: 'displayName',
              required: true,
            },
          ]}
        >
          <Input
            type="text"
            value={displayName}
            onChange={onChangeDisplayName}
            placeholder="사용자에게 보여지는 이름"
            allowClear
          />
        </Form.Item>
        <Form.Item
          name="type"
          label="보이스 타입"
          rules={[
            {
              type: 'string',
              required: true,
            },
          ]}
        >
          <Select placeholder="선택" allowClear>
            <Option value="GENERIC">GENERIC</Option>
            <Option value="CUSTOM">CUSTOM</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="localeId"
          label="언어"
          rules={[
            {
              type: 'string',
              required: true,
            },
          ]}
        >
          <Select placeholder="Select a language" allowClear>
            {languageOptions.map((option) => (
              <Option key={option.id} value={option.id.toString()}>
                {option.language}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="gender"
          label="성별"
          rules={[
            {
              type: 'gender',
              required: true,
            },
          ]}
        >
          <Select placeholder="성별선택" allowClear>
            <Option value="male">남</Option>
            <Option value="female">여</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="tag"
          label="태그"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            type="text"
            value={tag}
            onChange={onChangeTag}
            placeholder="밝은|20대|여성 (|로 구분해주세요 꼭. 짝대기 입니다)"
            allowClear
          />
        </Form.Item>
        <Form.Item
          name="level"
          label="레벨"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="number" value={level} onChange={onChangeLevel} placeholder="레벨 입력" allowClear />
        </Form.Item>
        <Form.Item
          name="engineCode"
          label="엔진 코드"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="text" value={engineCode} onChange={onChangeEngineCode} placeholder="26|10021" allowClear />
        </Form.Item>
        <Form.Item
          name="coef"
          label="coef"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="text" value={coef} onChange={(e) => setCoef(e.target.value)} placeholder="coef" allowClear />
        </Form.Item>
        <Form.Item
          name="intercept"
          label="intercept"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            type="text"
            value={engineCode}
            onChange={(e) => setIntercept(e.target.value)}
            placeholder="intercept"
            allowClear
          />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            ...layout.wrapperCol,
            offset: 0,
          }}
        >
          <Button type="primary" htmlType="submit" disabled={fileList.length === 0} loading={uploading} block>
            {uploading ? 'Uploading' : 'Create'}
          </Button>
        </Form.Item>

        <Row justify="end">
          <Col>
            <Link to="/resources/voices" style={{ fontSize: '16px' }}>
              List
            </Link>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default withTitleMetaData(CreateVoice)
