import { atom, selector, selectorFamily } from 'recoil'
import { recoilPersist } from 'recoil-persist'
import _ from 'lodash'
import apiClient from '../service/api'

const { persistAtom } = recoilPersist()
const loginState = atom({
  key: 'loginState',
  default: { isLogin: false, workspace: undefined },
  effects_UNSTABLE: [persistAtom],
})

const currentWorkspace = atom({
  key: 'currentWorkspace',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
})

const voiceList = selectorFamily({
  key: 'voiceList',
  get: (param) => async () => {
    const { workspaceId, accessToken } = param
    const response = await apiClient.get(`/v1/workspaces/${workspaceId}/voices`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })

    const t = _.groupBy(response.data, 'voice.language')
    console.log(t)

    return response.data
  },
})

const aivatarList = selectorFamily({
  key: 'aivatarList',
  get: (param) => async () => {
    const { workspaceId, accessToken } = param
    const response = await apiClient.get(`/v1/workspaces/${workspaceId}/aivatars`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    console.log('aivatar!!')
    return response.data
  },
})

const voiceWorkspace = selector({
  key: 'voiceWorkspace',
  get: ({ get }) =>
    get(
      voiceList({
        workspaceId: get(currentWorkspace).id,
        accessToken: get(loginState).accessToken,
      }),
    ),
})

const aivatarWorkspace = selector({
  key: 'aivatarWorkspace',
  get: ({ get }) =>
    get(
      aivatarList({
        workspaceId: get(currentWorkspace).id,
        accessToken: get(loginState).accessToken,
      }),
    ),
})

export { loginState, currentWorkspace, voiceWorkspace, aivatarWorkspace }
