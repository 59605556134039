import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Typography, message, Alert } from 'antd'
import { useRecoilState, useSetRecoilState } from 'recoil'
import AuthForm from '../components/AuthForm'
import { AuthState, currentWorkspace, loginState } from '../atoms'
import apiClient from '../service/api'
import env from '../constants/env'

function Login() {
  const navigate = useNavigate()
  const [authState, setAuthState] = useRecoilState(AuthState)
  console.log(authState)
  const [errorAlert, setErrorAlert] = useState('')
  const [spin, setSpin] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()
  const [showError, setShowError] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [login, setLogin] = useRecoilState(loginState)
  const setCurrentWorkspace = useSetRecoilState(currentWorkspace)

  const errorMsg = () => {
    messageApi.open({
      type: 'error',
      content: '로그인에 실패하였습니다.',
      className: 'custom-class',
    })
  }

  const loginSubmit = async (form) => {
    setSpin(true)
    try {
      const { data, status } = await apiClient.post(`/v1/auth/login`, JSON.stringify(form))
      console.log(data.accessToken)
      if (status === 200) {
        setLogin({ isLogin: true, ...data })
        if (data.workspaceList.length === 1) {
          console.log('hello')
          setCurrentWorkspace(data.workspaceList[0])
        }
        setAuthState({ loggedIn: true, ...data })
        // localStorage.setItem("auth", true);
        localStorage.setItem('token', data.accessToken)

        // login 이후 이동 페이지~~~~~~~~~~~~~~~~
        navigate('/')
        // navigate("/login");
      }
    } catch (error) {
      setSpin(false)
      setShowError(true)
      switch (error.response.status) {
        case 400:
          setErrorAlert('잘못된 요청 입니다.')
          errorMsg()
          break
        case 403:
          setErrorAlert('계정정보를 확인해 주세요.')
          errorMsg()
          break
        case 404:
          setErrorAlert('요청하신 리소스를 찾을 수 없습니다')
          errorMsg()
          break
        default:
          setErrorAlert('일시적인 오류로 로그인을 할 수 없습니다. 잠시 후 다시 시도해 주십시오')
          errorMsg()
          break
      }
    }
  }

  return (
    <Row className="Login" justify="space-around" align="middle" style={{ height: '100vh' }}>
      <Col span={8}>
        {authState.loggedIn ? (
          <div>This page is Login page, You are logged in</div>
        ) : (
          <>
            <Typography align="center" style={{ marginBottom: '16px' }}>
              <img src={`${env.PUBLIC_URL}/assets/images/aipark-big-ci.png`} width="200" alt="AIPark" />
            </Typography>
            <AuthForm submit={loginSubmit} spin={spin} label="LOG IN" />
            {contextHolder}
            {showError ? <Alert message="Error" description={errorAlert} type="error" showIcon /> : null}
          </>
        )}
      </Col>
    </Row>
  )
}
export default Login
