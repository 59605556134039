import React, { useState } from 'react'
import { Button, Form, Input, Select, Typography, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import apiClient from '../../../service/api'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { Title, Text } = Typography
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { Option } = Select

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const layout = {
  labelCol: {
    span: 0,
  },
  wrapperCol: {
    span: 24,
  },
}

/* eslint-disable no-template-curly-in-string */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const validateMessages = {
  required: '${label} 은 필수 항목 입니다.',
  types: {
    name: '${label} 을 입력해 주세요.',
    displayName: '${label} 을 입력해 주세요.',
    file: '${label} 를 등록해 주세요.',
    language: '${label} 를 등록해 주세요.',
    gender: '${label} 를 등록해 주세요.',
  },
}
/* eslint-enable no-template-curly-in-string */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
}

export default function CreateVoiceV2() {
  const [form] = Form.useForm()

  const getFile = (e) => {
    console.log('upload event: ', e)

    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const onFinish = (values) => {
    const token = localStorage.getItem('token')
    const formData = new FormData()

    Object.entries(values).forEach(([fieldName, fieldValue]) => {
      if (fieldName === 'file') {
        return
      }
      formData.append(fieldName, fieldValue) // there should be values.avatar which is a File object
    })

    // todo: upload file이 없으면!
    formData.append('file', values.file[0].originFileObj)
    // adding path
    formData.append('samplePath', values.file[0].name)

    apiClient
      .post('/v1/resources/voices', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => console.log(data))
      .catch(alert)
  }

  const [fileList, setFileList] = useState([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uploading, setUploading] = useState(false)

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file])
      return false
    },
    action: (file) => {
      console.log(file)
    },
    fileList,
  }

  return (
    <Form name="audioRegister" form={form} onFinish={onFinish}>
      <Form.Item name="file" getValueFromEvent={getFile}>
        <Upload {...props} maxCount={1}>
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>
      </Form.Item>
      <Form.Item name="name" label="name" tooltip="보이스 코드 네임">
        <Input />
      </Form.Item>
      <Form.Item name="displayName" label="displayName" tooltip="사용자에게 보여지는 이름">
        <Input />
      </Form.Item>
      <Form.Item name="language" label="language" tooltip="SelectBox -> 리스트는 잘 몰름">
        <Input />
      </Form.Item>
      <Form.Item name="gender" label="gender" tooltip="남 / 여">
        <Input />
      </Form.Item>
      {/* <Form.Item name={"available"} label={"available"} tooltip={"기본값 false!, Switch!"}> */}
      {/*  <Input placeholder={"true or false"}></Input> */}
      {/* </Form.Item> */}

      <Button htmlType="submit">등록하기</Button>
    </Form>
  )
}
