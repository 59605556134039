import React from 'react'
import { useParams } from 'react-router-dom'
import Workspace from '.'
import withTitleMetaData from '../../../hocs/withTitleMetaData'

function WorkspaceContainer() {
  const { workspaceId } = useParams()

  if (!workspaceId) throw new Error('workspaceId가 없습니다.')

  return <Workspace workspaceId={Number(workspaceId)} />
}

export default withTitleMetaData(WorkspaceContainer)
