import { Typography } from 'antd'
import React, { ComponentType } from 'react'
import { Helmet } from 'react-helmet-async'

type Props = {
  title: string
}

const withTitleMetaData = <P extends object>(WrappedComponent: ComponentType<P>) => {
  function HOC({ title, ...props }: P & Props) {
    return (
      <>
        <Helmet>
          <title>{title} | AIPark Admin</title>
        </Helmet>
        <Typography.Title level={2}>{title}</Typography.Title>
        <WrappedComponent {...(props as P)} />
      </>
    )
  }

  return HOC
}

export default withTitleMetaData
