import { atom, useRecoilState } from 'recoil'

const AuthState = atom({
  key: 'AuthState',
  default: { loggedIn: false, email: '', password: '', role: 'user' },
})

export { AuthState }

export const useAuthState = () => useRecoilState(AuthState)
