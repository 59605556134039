import React, { useCallback, useRef } from 'react'
import { Typography, Button, Form, Input, Modal } from 'antd'
import { useNavigate } from 'react-router-dom'
import apiClient from '../../service/api'
import useInput from '../../hooks/useInput'
import withTitleMetaData from '../../hocs/withTitleMetaData'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { Title, Text, Link } = Typography

const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 20,
  },
}

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} 은 필수 항목 입니다.',
  types: {
    email: '${label} 을 입력해 주세요.',
    name: '${label} 을 입력해 주세요.',
    password: '${label} 를 입력해 주세요.',
  },
}
/* eslint-enable no-template-curly-in-string */

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 20,
    },
  },
}

function RegisterUser() {
  // RegisterUser.propTypes = {
  //   title: PropTypes.string,
  // }

  const navigate = useNavigate()
  const [emailInput, setEmailInput] = useInput('')
  const [nameInput, setNameInput] = useInput('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [passwordInput, setPasswordInput] = useInput('')

  const [form] = Form.useForm()
  const formRef = useRef(null)

  /**
   * Request Register User
   * @type {(function({email: *, name: *, password: *}): void)|*}
   */
  const onsubmitForm = useCallback(
    ({ email, name, password }) => {
      apiClient
        .post('/v1/auth/register', { email, name, password })
        .catch((e) => {
          console.error(e)
        })
        .then((response) => {
          if (response.status === 200) {
            Modal.success({
              title: '회원 등록이 정상적으로 되었습니다.',
              content: '회원 목록으로 이동합니다.',
              onOk() {
                navigate('/user')
              },
            })
            formRef.current?.resetFields()
          }
        })
    },
    [navigate],
  )

  /**
   * name 값 validation
   * initial setting : 한글1~10자, 영문 및 숫자 2~20자, 공백 불가
   * @type {(function(*, *): (Promise<never>))|*}
   */
  const validateName = useCallback((_, value) => {
    if (!value) {
      return Promise.reject(new Error('이름은 필수 항목 입니다.'))
    }
    if (/\s/.test(value)) {
      return Promise.reject(new Error('이름은 공백을 포함 할 수 없습니다.'))
    }

    let nameLength = 0
    for (let i = 0; i < value.length; i += 1) {
      const char = value.charAt(i)
      if (encodeURIComponent(char).length > 4) {
        nameLength += 2
      } else {
        nameLength += 1
      }
    }
    if (nameLength < 2 || nameLength >= 20) {
      return Promise.reject(new Error('이름은 한글1~10자, 영문 및 숫자 2~20자까지 입력가능합니다.'))
    }

    const regExp = /[^a-zA-Z0-9가-힣_]/
    if (regExp.test(value)) {
      return Promise.reject(new Error('이름은 한글, 영문, 숫자, _ 만 사용할 수 있습니다.'))
    }
    return Promise.resolve()
  }, [])

  /**
   * Password validation
   * initial setting : 8~50자이며 영문 소문자, 영문 대문자, 숫자, 특수문자를 모두 포함
   *
   * @type {(function(*, *): (Promise<never>))|*}
   */
  const validatePassword = useCallback((_, value) => {
    // const regExp =
    //   /(?=.*\d{1,50})(?=.*[~`!@#$%\\^&*()-+=]{1,50})(?=.*[a-z]{1,50})(?=.*[A-Z]{1,50}).{8,50}$/;
    if (!value) {
      return Promise.reject(new Error('비밀번호는 필수 항목 입니다.'))
    }
    // if (!regExp.test(value)) {
    //   return Promise.reject(
    //     new Error(
    //       "비밀번호는 8~50자이며 영문 소문자, 영문 대문자, 숫자, 특수문자를 모두 포함해야 합니다.",
    //     ),
    //   );
    // }
    return Promise.resolve()
  }, [])

  // const registerUserHandler = async (form) => {
  //   try {
  //     const { data, status } = await apiClient.post(
  //       `/v1/auth/register`,
  //       JSON.stringify(form),
  //     );
  //     console.log(data);
  //     if (status === 200) {
  //       alert("등록 성공");
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function getPostHeader(object) {
    return {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(object),
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onFinish = (values) => {
    console.log('Finish:', values)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <div>
      <Form
        {...formItemLayout}
        layout="vertical"
        form={form}
        ref={formRef}
        name="register-user"
        onFinish={onsubmitForm}
        onFinishFailed={onFinishFailed}
        style={{
          maxWidth: 480,
          margin: '0 auto',
        }}
        scrollToFirstError
        validateMessages={validateMessages}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              type: 'email',
              required: true,
            },
          ]}
        >
          <Input type="email" placeholder="example@aipark.ai" value={emailInput} onChange={setEmailInput} allowClear />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              type: 'name',
              required: true,
              validator: validateName,
            },
          ]}
        >
          <Input type="text" value={nameInput} onChange={setNameInput} placeholder="Name" allowClear />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              type: 'password',
              required: true,
              validator: validatePassword,
            },
          ]}
        >
          <Input.Password
            type="password"
            value={passwordInput}
            placeholder="Password"
            onChange={setPasswordInput}
            allowClear
          />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            ...layout.wrapperCol,
            offset: 0,
          }}
        >
          <Button type="primary" htmlType="submit" block>
            Register
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
export default withTitleMetaData(RegisterUser)
