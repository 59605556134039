import apiClient from '../service/api'

export const getAllAudios = async (page) => {
  const token = localStorage.getItem('token')

  const { data } = await apiClient.get(`/v1/audios?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return data
}

export const loadAudio = async (audioId) => {
  const token = localStorage.getItem('token')
  const response = await apiClient.get(`/v1/audios/${audioId}/listen`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  })
  const audioUrl = URL.createObjectURL(response.data)
  return audioUrl
}
