import React, { useEffect, useRef, useState } from 'react'
import { Typography, Button, Form, Input, Select, Modal, message, Col, Row } from 'antd'
import { Link } from 'react-router-dom'
import TextArea from 'antd/es/input/TextArea'
import useInput from '../../../hooks/useInput'
import apiClient from '../../../service/api'
import withTitleMetaData from '../../../hocs/withTitleMetaData'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { Title, Text } = Typography
const { Option } = Select

const layout = {
  labelCol: {
    span: 0,
  },
  wrapperCol: {
    span: 24,
  },
}

const formItemLayout = {
  labelCol: {
    xs: {
      span: 8,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 32,
    },
    sm: {
      span: 32,
    },
  },
}

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} 은 필수 항목 입니다.',
  types: {
    name: '${label} 을 입력해 주세요.',
    modelType: '${label} 을 입력해 주세요.',
  },
}
/* eslint-enable no-template-curly-in-string */
function CreateActingVideo() {
  // CreateActingVideo.propTypes = {
  //   title: PropTypes.string,
  // }

  const [name, onChangeName] = useInput('')
  const [matteMaskThreshold, onChangeMattMaskThreshold] = useInput(0)
  const [frameRate, onChangeFrameRate] = useInput(0)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [gender, onChangeGender] = useInput('')
  const [memo, onChangeMemo] = useInput('')
  const [code, onChangeCode] = useInput('')
  const [aivatarName, onChangeAivatarName] = useInput('')

  const [form] = Form.useForm()
  const formRef = useRef(null)

  const [uploading, setUploading] = useState(false)
  const [disabled, setDisabled] = useState(true)

  const [messageApi, contextHolder] = message.useMessage()

  /**
   * API 에러 메시지 UI
   * @param msg
   */
  const errorMsg = (msg) => {
    messageApi.open({
      type: 'error',
      content: msg,
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const onFinish = (values) => {
    const token = localStorage.getItem('token')

    setUploading(true)
    apiClient
      .post('/v1/resources/actingvideos', values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          Modal.success({
            title: '행동영상 생성이 정상적으로 되었습니다.',
          })
          setUploading(false)
          formRef.current?.resetFields()
          setDisabled(true)
        }
      })
      .catch((e) => {
        console.error(e)
        if (e) {
          errorMsg(e.message)
        }
        setUploading(false)
      })
      .finally(() => {
        setUploading(false)
        form.resetFields()
        setDisabled(true)
      })
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  useEffect(() => {
    if (name) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [name])

  return (
    <div>
      {contextHolder}

      <Form
        {...formItemLayout}
        layout="horizontal"
        form={form}
        ref={formRef}
        name="register-model"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{
          maxWidth: 480,
          margin: '0 auto 0 128px',
        }}
        scrollToFirstError
        validateMessages={validateMessages}
      >
        <Form.Item
          name="name"
          label="Model Name"
          rules={[
            {
              type: 'name',
              required: true,
            },
          ]}
        >
          <Input
            type="text"
            placeholder="Avatar - contents.xlsx: PCM_M_FORMAL_1_1"
            value={name}
            onChange={onChangeName}
            allowClear
          />
        </Form.Item>

        <Form.Item
          name="matteMaskThreshold"
          label="mattMaskThreshold"
          rules={[
            {
              type: 'mattMaskThreshold',
              required: true,
            },
          ]}
        >
          <Input
            type="number"
            value={matteMaskThreshold}
            onChange={onChangeMattMaskThreshold}
            placeholder="mattMaskThreshold"
            allowClear
          />
        </Form.Item>

        <Form.Item
          name="frameRate"
          label="frameRate"
          rules={[
            {
              type: 'frameRate',
              required: true,
            },
          ]}
        >
          <Input
            type="number"
            value={frameRate}
            onChange={onChangeFrameRate}
            placeholder="3000, frameRate * 100. 30 frame to 3000"
            allowClear
          />
        </Form.Item>

        <Form.Item
          name="gender"
          label="gender"
          rules={[
            {
              type: 'gender',
              required: true,
            },
          ]}
        >
          <Select placeholder="Select a gender">
            <Option value="FEMALE">FEMALE</Option>
            <Option value="MALE">MALE</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="memo"
          label="memo"
          rules={[
            {
              type: 'memo',
            },
          ]}
        >
          <TextArea placeholder="input memo" value={memo} onChange={onChangeMemo} rows={4} />
        </Form.Item>

        <Form.Item
          name="code"
          label="code"
          rules={[
            {
              type: 'code',
            },
          ]}
        >
          <TextArea placeholder="input code" value={code} onChange={onChangeCode} rows={4} />
        </Form.Item>

        <Form.Item
          name="aivatarName"
          label="Aivatar Name"
          rules={[
            {
              type: 'aivatarName',
            },
          ]}
        >
          <TextArea placeholder="input aivatar name" value={aivatarName} onChange={onChangeAivatarName} rows={4} />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            ...layout.wrapperCol,
            offset: 0,
          }}
        >
          <Button type="primary" htmlType="submit" disabled={disabled} loading={uploading} block>
            {uploading ? 'Process...' : 'Create'}
          </Button>
        </Form.Item>
        <Row justify="end">
          <Col>
            <Link to="/resources/actingvideos" style={{ fontSize: '16px' }}>
              List
            </Link>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default withTitleMetaData(CreateActingVideo)
