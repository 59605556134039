import React, { useEffect, useRef, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Typography, Descriptions, message, Button, Input, Modal, Col, Row, Form, Tag, Table } from 'antd'

import useInput from '../../../hooks/useInput'
import { getModel } from '../../../Actions'
import apiClient from '../../../service/api'
import withTitleMetaData from '../../../hocs/withTitleMetaData'
import FemaleAvatar from '../../../assets/images/female_avatar.png'
import MaleAvatar from '../../../assets/images/male_avatar.png'

const { Title, Text } = Typography

const formItemLayout = {
  wrapperCol: {
    xs: {
      none: 600,
    },
    sm: {
      none: 600,
    },
  },
}

// const gridStyle = {
//   width: '20%',
//   textAlign: 'left',
// }

// 상세 dara 없을 때
function NoData() {
  return (
    <Text disabled italic>
      none
    </Text>
  )
}

function Model({ title }) {
  // Model.propTypes = {
  //   title: PropTypes.string,
  // }

  const { modelId } = useParams()

  const [modelData, setModelData] = useState({})

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLoading, isError, data } = useQuery({
    queryKey: ['getModel', modelId],
    queryFn: () => getModel(modelId),
    staleTime: 500,
    onError: (e) => {
      // API 연결이 실패한 경우에 호출됨
      console.log(e.message)
    },
  })

  useEffect(() => {
    if (data) {
      setModelData(data)
      console.log(data)
    }
  }, [data])

  const [videoIds, onChangeVideoIds] = useInput([])

  const [form] = Form.useForm()
  const formRef = useRef(null)

  const [uploading, setUploading] = useState(false)
  const [disabled, setDisabled] = useState(true)

  const [formIds, setFormIds] = useState([])

  const [messageApi, contextHolder] = message.useMessage()

  /**
   * API 에러 메시지 UI
   * @param msg
   */
  const errorMsg = (msg) => {
    messageApi.open({
      type: 'error',
      content: msg,
    })
  }

  const handleSubmit = () => {
    // 새로운 값을 배열에 추가
    if (!Number.isNaN(Number(videoIds))) {
      setFormIds([...formIds, Number(videoIds)])
    }
  }

  const handleFormSubmit = () => {
    const token = localStorage.getItem('token')
    // let formData = [];
    // formData = new FormData();
    // formData.append("videoIds", JSON.stringify(formIds));

    setUploading(true)
    apiClient
      .put(
        `/v1/resources/models/${modelData.id}`,
        { videoIds: formIds },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        // API 응답을 처리하는 코드
        console.log('API Response:', res)
        if (res.status === 200) {
          Modal.success({
            title: `${formIds} 후보 매핑이 정상적으로 되었습니다.`,
          })
          setUploading(false)
          formRef.current?.resetFields()
          setDisabled(true)
        }
      })
      .catch((error) => {
        // 오류 처리 코드
        console.error('Error:', error)
        if (error) {
          errorMsg(error.message)
        }
        setUploading(false)
      })
      .finally(() => {
        setUploading(false)
        form.resetFields()
        setDisabled(true)
        setFormIds([])
      })
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const deleteIds = (e) => {
    const filtered = formIds.filter((element) => element !== e)
    setFormIds(filtered)
  }

  useEffect(() => {
    if (formIds.length >= 1) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
    console.log(formIds)
  }, [formIds])

  return (
    <div>
      <Title level={3}>
        {`"${modelData.name}"`} ({modelId})
      </Title>

      <Descriptions title={title} size="default" layout="horizontal" bordered column={2}>
        <Descriptions.Item label="Model Type" labelStyle={{ width: '15%' }} contentStyle={{ width: '35%' }}>
          {modelData.modelType ?? <NoData />}
        </Descriptions.Item>
        <Descriptions.Item label="Weight Path" labelStyle={{ width: '15%' }} contentStyle={{ width: '35%' }}>
          {modelData.weightPath ?? <NoData />}
        </Descriptions.Item>
        <Descriptions.Item label="faceType">{modelData.faceType ?? <NoData />}</Descriptions.Item>
        <Descriptions.Item label="imageSize">{modelData.imageSize ?? <NoData />}</Descriptions.Item>
        <Descriptions.Item label="mode">{modelData.mode ?? <NoData />}</Descriptions.Item>
        <Descriptions.Item label="maskMode">{modelData.maskMode ?? <NoData />}</Descriptions.Item>
        <Descriptions.Item label="erodeMaskModifier">{modelData.erodeMaskModifier ?? <NoData />}</Descriptions.Item>
        <Descriptions.Item label="blurMaskModifier">{modelData.blurMaskModifier ?? <NoData />}</Descriptions.Item>
        <Descriptions.Item label="motionBlurPower">{modelData.motionBlurPower ?? <NoData />}</Descriptions.Item>
        <Descriptions.Item label="outputFaceScale">{modelData.outputFaceScale ?? <NoData />}</Descriptions.Item>
        <Descriptions.Item label="colorTransferMode">{modelData.colorTransferMode ?? <NoData />}</Descriptions.Item>
        <Descriptions.Item label="sharpenMode">{modelData.sharpenMode ?? <NoData />}</Descriptions.Item>
        <Descriptions.Item label="blursharpenAmount">{modelData.blursharpenAmount ?? <NoData />}</Descriptions.Item>
        <Descriptions.Item label="superResolutionPower">
          {modelData.superResolutionPower ?? <NoData />}
        </Descriptions.Item>
        <Descriptions.Item label="imageDenoisePower">{modelData.imageDenoisePower ?? <NoData />}</Descriptions.Item>
        <Descriptions.Item label="bicubicDegradePower">{modelData.bicubicDegradePower ?? <NoData />}</Descriptions.Item>
        <Descriptions.Item label="colorDegradePower">{modelData.colorDegradePower ?? <NoData />}</Descriptions.Item>
      </Descriptions>

      <div style={{ marginBottom: 20 }}>
        <Typography.Title level={4}>Acting Videos</Typography.Title>
        <Table
          dataSource={modelData.actingVideoList}
          pagination={false}
          rowKey={(record) => record.id}
          columns={[
            {
              title: ' ID',
              dataIndex: 'id',
              key: 'id',
            },
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
            },
            {
              key: 'gender',
              title: '성별',
              dataIndex: 'gender',
              width: '10%',
              render: (t, r) => (
                <img src={r.gender === 'FEMALE' ? FemaleAvatar : MaleAvatar} width="40" alt={r.gender} />
              ),
            },
            {
              key: 'video',
              title: 'Video',
              dataIndex: 'video',
              render: (t, r) => (
                <a href={r.video} target="_blank" rel="noopener noreferrer">
                  {r.video}
                </a>
              ),
            },
          ]}
          scroll={{ y: 65 * 10 }}
        />
      </div>
      {/* <Card title="Acting Video List" style={{ marginTop: '32px' }}>
        {modelData.actingVideoList &&
          modelData.actingVideoList.map((video) => (
            <Card.Grid style={gridStyle} key={video.id}>
              <ul>
                <li>{video.id}</li>
                <li>
                  <Text type="secondary">{video.name}</Text>
                </li>
                <li>
                  {video.deepLearningModelList &&
                    video.deepLearningModelList.map((model) => <Tag key={model.id}>{model}</Tag>)}
                </li>
              </ul>
            </Card.Grid>
          ))}
      </Card> */}

      <Row justify="space-between" style={{ marginTop: '24px' }}>
        {/* <Button> */}
        {/*  <Link to={`/resources/models/update/${modelData.id}`}>정보 수정</Link> */}
        {/* </Button> */}
        <div />
        <Col>
          <Link to="/resources/models" style={{ fontSize: '16px' }}>
            List
          </Link>
        </Col>
      </Row>

      <Descriptions title="아이바타 후보 매핑" size="small" layout="vertical" bordered style={{ marginTop: '24px' }}>
        <Descriptions.Item label="VideoIds">
          {contextHolder}
          <Form
            {...formItemLayout}
            layout="inline"
            form={form}
            ref={formRef}
            name="register-model"
            onFinish={handleSubmit}
            onFinishFailed={onFinishFailed}
            style={{
              maxWidth: 600,
            }}
            scrollToFirstError
          >
            <Form.Item
              name="videoIds"
              label="videoIds 입력"
              rules={[
                {
                  type: 'videoIds',
                  required: true,
                },
              ]}
            >
              <Input type="text" placeholder="ID 숫자만 입력" value={videoIds} onChange={onChangeVideoIds} allowClear />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 0,
              }}
            >
              <Button type="primary" htmlType="submit" disabled={videoIds.length <= 1}>
                Add
              </Button>
              <Button style={{ marginLeft: '16px' }} type="default" onClick={() => setFormIds([])}>
                Clear
              </Button>
            </Form.Item>
          </Form>
          <Row style={{ padding: '24px 0' }}>
            {formIds.map((formId) => (
              <Tag
                closable
                key={formId}
                color="purple"
                onClose={(e) => {
                  e.preventDefault()
                  deleteIds(formId)
                }}
              >
                {formId}
              </Tag>
            ))}
          </Row>
          <Row>
            <Button type="primary" disabled={disabled} onClick={handleFormSubmit} loading={uploading}>
              {uploading ? 'Process...' : '후보 매핑하기'}
            </Button>
          </Row>
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}

export default withTitleMetaData(Model)
