import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import {
  Typography,
  Button,
  Col,
  Row,
  Card,
  Avatar,
  Space,
  Divider,
  Modal,
  Form,
  Upload,
  Input,
  message,
  Drawer,
  Table,
  Pagination,
} from 'antd'
import { Link } from 'react-router-dom'
import { FileSearchOutlined, FormOutlined, PlusOutlined, SmileOutlined, VideoCameraOutlined } from '@ant-design/icons'
import { NumberParam, useQueryParam } from 'use-query-params'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import FemaleAvatar from '../../../assets/images/female_avatar.png'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import MaleAvatar from '../../../assets/images/male_avatar.png'
import apiClient from '../../../service/api'
import { getAllAivatars } from '../../../Actions'
import withTitleMetaData from '../../../hocs/withTitleMetaData'

const { Text } = Typography
const { Meta } = Card

function Aivatars() {
  // Aivatars.propTypes = {
  //   title: PropTypes.string,
  // }

  const [posts, setPosts] = useState([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageable, setPageable] = useState({})
  const [totalElements, setTotalElements] = useState(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalPages, setTotalPages] = useState(null)

  /**
   * Modal Actions Starts
   */
  const [isModalOpen, setIsModalOpen] = useState(false)
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleModelCancel = () => {
    setIsModalOpen(false)
  }
  const [form] = Form.useForm()

  const [fileList, setFileList] = useState([])
  const [uploading, setUploading] = useState(false)

  const fileProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file])
      return false
    },
    action: (file) => {
      console.log(file)
    },
    fileList,
  }

  const getFile = (e) => {
    console.log('Upload event:', e)
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [previewOpen, setPreviewOpen] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [previewImage, setPreviewImage] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [previewTitle, setPreviewTitle] = useState('')

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCancel = () => setPreviewOpen(false)
  const handlePreview = async (file) => {
    const previewFile = file

    if (!previewFile.url && !previewFile.preview) {
      previewFile.preview = await getBase64(previewFile.originFileObj)
    }
    setPreviewImage(previewFile.url || previewFile.preview)
    setPreviewOpen(true)
    setPreviewTitle(previewFile.name || previewFile.url.substring(previewFile.url.lastIndexOf('/') + 1))
  }
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList)
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  )

  const [previewFileList, setPreviewFileList] = useState([])
  // const [uploading, setUploading] = useState(false);

  const previewFileProps = {
    onRemove: (file) => {
      const index = previewFileList.indexOf(file)
      const newFileList = previewFileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      previewFileList([...previewFileList, file])
      return false
    },
    action: (file) => {
      console.log(file)
    },
    fileList,
  }

  const getPreviewFile = (e) => {
    console.log('Upload event:', e)
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const handlePreviewChange = ({ fileList: newFileList }) => setPreviewFileList(newFileList)

  const onFinish = (values) => {
    const token = localStorage.getItem('token')
    const formData = new FormData()

    Object.entries(values).forEach(([name, value]) => {
      if (name === 'preview' || name === 'thumbnail') {
        return
      }
      formData.append(name, value) // there should be values.avatar which is a File object
    })

    console.log(values)
    // todo: upload file이 없으면!
    formData.append('preview', values.preview[0].originFileObj)
    formData.append('thumbnail', values.thumbnail[0].originFileObj)
    setUploading(true)
    apiClient
      .post('/v1/resources/mappings', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => {
        console.log(data)
        setFileList([])
        message.success('upload successfully.')
      })
      .catch(() => {
        message.error('upload failed.')
      })
      .finally(() => {
        setUploading(false)
        form.resetFields()
      })
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  /**
   * Modal Actions End
   */

  const [page, setPage] = useQueryParam('page', NumberParam)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLoading, isError, error, isFetching, isPreviousData, data } = useQuery({
    queryKey: ['getAllAivatars', page],
    queryFn: () => getAllAivatars(page ? page - 1 : 0),
    staleTime: 500,
    keepPreviousData: true,
    onError: (e) => {
      // API 연결이 실패한 경우에 호출됨
      console.log(e.message)
    },
  })

  useEffect(() => {
    if (data) {
      setPosts(data.content)
      setPageable(data.pageable)
      setTotalElements(data.totalElements)
      setTotalPages(data.totalPages - 1)
    }
  }, [data])

  // const queryClient = useQueryClient()

  // useEffect(() => {
  //   const nextPage = page + 1
  //   queryClient.prefetchQuery(['getAllAivatars', nextPage], () => getAllAivatars(nextPage)) // 다음 페이지의 데이터를 미리 fetch
  // }, [page, queryClient])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const columns = [
    {
      title: 'NO',
      key: 'index',
      render: (t, r) => <Text type="secondary">{data.indexOf(r) + 1}</Text>,
      width: '7%',
    },
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
    },
    {
      key: 'gender',
      title: 'Gender',
      dataIndex: 'gender',
    },
    {
      title: 'Detail',
      dataIndex: 'detail',
      render: (detail) => detail.map((r) => r.id).join(),
      key: 'detail',
    },
  ]

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [drawerData, setDrawerData] = useState(null)

  const showDrawer = (detail) => {
    setDrawerData(detail)
    setDrawerOpen(true)
  }

  const closeDrawer = () => {
    setDrawerOpen(false)
  }

  return (
    <div>
      <Row justify="end">
        <Col>
          <Link to="/resources/aivatars/create">
            <Button type="primary" size="large">
              <FormOutlined /> 아이바타 등록
            </Button>
          </Link>
        </Col>
        <Col>
          <Button type="primary" onClick={showModal}>
            Open Modal
          </Button>
          <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleModelCancel}>
            <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <Form.Item
                name="aivatarId"
                label="아이바타Id"
                rules={[
                  {
                    type: 'name',
                    required: true,
                  },
                ]}
              >
                <Input type="text" placeholder="aivatarId" allowClear />
              </Form.Item>
              <Form.Item
                name="videoId"
                label="행동영상Id"
                rules={[
                  {
                    type: 'name',
                    required: true,
                  },
                ]}
              >
                <Input type="text" placeholder="행동영상Id" allowClear />
              </Form.Item>
              <Form.Item
                name="modelId"
                label="딥러닝모델Id"
                rules={[
                  {
                    type: 'name',
                    required: true,
                  },
                ]}
              >
                <Input type="text" placeholder="딥러닝모델Id" allowClear />
              </Form.Item>
              <Form.Item
                name="groupName"
                label="그룹 이름"
                rules={[
                  {
                    type: 'name',
                    required: true,
                  },
                ]}
              >
                <Input type="text" placeholder="select2 그룹 이름" allowClear />
              </Form.Item>
              <Form.Item name="thumbnail" label="썸네일" getValueFromEvent={getFile}>
                <Upload
                  {...fileProps}
                  name="thumbnail"
                  maxCount={1}
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                >
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>
              </Form.Item>
              <Form.Item name="preview" label="preview webm 파일" getValueFromEvent={getPreviewFile}>
                <Upload
                  {...previewFileProps}
                  name="preview"
                  maxCount={1}
                  listType="picture-card"
                  fileList={previewFileList}
                  onChange={handlePreviewChange}
                >
                  {previewFileList.length >= 1 ? null : uploadButton}
                </Upload>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={fileList.length === 0} loading={uploading} block>
                  {uploading ? 'Uploading' : 'Create'}
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </Col>
      </Row>

      <Row>
        <Space align="center" split={<Divider type="vertical" />}>
          <Text type="secondary">
            <VideoCameraOutlined /> Acting Video
          </Text>
        </Space>

        <Space align="center" style={{ marginLeft: '16px' }}>
          <Text type="secondary">
            <SmileOutlined /> DeepLearning Model
          </Text>
        </Space>
      </Row>

      {posts &&
        posts.map((aivatar) => (
          <Card key={aivatar.id} style={{ marginTop: '16px' }}>
            <Meta
              avatar={<Avatar style={{ width: '64px', height: '64px' }} src={aivatar.thumbnailSrc} size={100} />}
              title={aivatar.name}
              description={`id: ${aivatar.id} gender: ${aivatar.gender}`}
              style={{ float: 'left', width: '220px' }}
            />
            <div
              style={{
                float: 'left',
                width: 'calc(100% - 244px)',
                maxHeight: '186px',
                overflow: 'hidden',
                overflowY: 'auto',
              }}
            >
              <Row gutter={[16, 16]}>
                {aivatar.detail.map((r) => (
                  <Col key={r.seqId} span={8}>
                    <Card
                      size="small"
                      cover={
                        <img
                          src={r.thumbnailSrc}
                          style={{
                            float: 'left',
                            width: '48px',
                            margin: '6px 6px 0 6px',
                          }}
                          alt={r.actingVideo.gender}
                        />
                      }
                      onClick={() => showDrawer(r)}
                      style={{ cursor: 'pointer', transition: 'transform .2s' }}
                      hoverable
                    >
                      <div style={{ float: 'left' }}>
                        <Row>
                          <Text type="primary" style={{ fontSize: '12px' }}>
                            <VideoCameraOutlined style={{ marginRight: '6px' }} />
                            <Text type="secondary" style={{ fontSize: '12px' }}>
                              {r.actingVideo.name}
                            </Text>
                          </Text>
                        </Row>
                        <Row>
                          <Text type="primary" style={{ fontSize: '12px' }}>
                            <SmileOutlined style={{ marginRight: '6px' }} />
                            <Text type="secondary" style={{ fontSize: '12px' }}>
                              {r.deepLearningModel?.name}
                            </Text>
                          </Text>

                          <Text code type="secondary" style={{ marginLeft: '12px', fontSize: '12px' }}>
                            {r.deepLearningModel?.modelType}
                          </Text>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </Card>
        ))}
      <Drawer title={`Aivatar Details - ${drawerData?.groupName}`} open={drawerOpen} onClose={closeDrawer} width={720}>
        {/* Acting Video */}
        {drawerData?.actingVideo && (
          <div style={{ marginBottom: 20 }}>
            <Typography.Title level={4}>Acting Video</Typography.Title>
            <Table
              dataSource={[drawerData.actingVideo]}
              pagination={{
                total: totalElements,
                current: page,
                onChange: (newPage) => setPage(newPage),
                position: ['bottomCenter'],
              }}
              rowKey={(record) => record.id}
              columns={[
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                },
                {
                  title: 'Name',
                  dataIndex: 'name',
                  key: 'name',
                },
                {
                  title: 'Gender',
                  dataIndex: 'gender',
                  key: 'gender',
                },
                {
                  title: 'Video',
                  dataIndex: 'video',
                  key: 'video',
                  render: (t, r) => (
                    <a href={r.video} target="_blank" rel="noopener noreferrer">
                      {r.video}
                    </a>
                  ),
                },
                {
                  key: 'detail',
                  title: '상세보기',
                  render: (t, r) => (
                    <Link to={`/resources/videos/${r.id}`}>
                      <FileSearchOutlined />
                    </Link>
                  ),
                },
              ]}
            />
          </div>
        )}

        {/* Deep Learning Model */}
        {drawerData?.deepLearningModel && (
          <div style={{ marginBottom: 20 }}>
            <Typography.Title level={4}>Deep Learning Model</Typography.Title>
            <Table
              dataSource={[drawerData.deepLearningModel]}
              pagination={false}
              rowKey={(record) => record.id}
              columns={[
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                },
                {
                  title: 'Name',
                  dataIndex: 'name',
                  key: 'name',
                },
                {
                  title: 'Model Type',
                  dataIndex: 'modelType',
                  key: 'modelType',
                },
                {
                  title: 'Face Type',
                  dataIndex: 'faceType',
                  key: 'faceType',
                },
                {
                  title: 'Mode',
                  dataIndex: 'mode',
                  key: 'mode',
                },
                {
                  key: 'detail',
                  title: '상세보기',
                  render: (t, r) => (
                    <Link to={`/resources/models/${r.id}`}>
                      <FileSearchOutlined />
                    </Link>
                  ),
                },
              ]}
            />
          </div>
        )}
      </Drawer>

      {/* <PaginationComponent
        page={page}
        setPage={(newPage) => setPage(newPage)}
        totalPage={totalPages}
        totalCount={totalElements}
        size={pageable.pageSize}
        pageCount={10}
      /> */}
      <Pagination total={totalElements} current={page} onChange={(newPage) => setPage(newPage)} />
    </div>
  )
}

export default withTitleMetaData(Aivatars)
