import React from 'react'

function Notfound() {
  return (
    <div>
      <h1>Notfound</h1>
    </div>
  )
}
export default Notfound
