import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Form, Input, Select, InputNumber, Modal, message, Col, Row, Radio } from 'antd'
import { useNavigate, Link } from 'react-router-dom'
import apiClient from '../../service/api'
import useInput from '../../hooks/useInput'
import withTitleMetaData from '../../hocs/withTitleMetaData'

const { Option } = Select

const layout = {
  labelCol: {
    span: 0,
  },
  wrapperCol: {
    span: 24,
  },
}

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} 은 필수 항목 입니다.',
  types: {
    name: '${label} 을 입력해 주세요.',
    price: '${label} 을 입력해 주세요.',
    currency: '${label} 를 입력해 주세요.',
  },
}
/* eslint-enable no-template-curly-in-string */

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
}

function CreatePlan() {
  // CreatePlan.propTypes = {
  //   title: PropTypes.string,
  // }

  const navigate = useNavigate()
  const [name, onChangeName] = useInput('')
  const [displayName, onChangeDisplayName] = useInput('')
  const [description, onChangeDescription] = useInput('')
  const [price, onChangePrice] = useInput('')
  const [level, onChangeLevel] = useInput('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currency, onChangeCurrency] = useInput('')
  const [features, setFeatures] = useState([])
  const token = localStorage.getItem('token')

  const [form] = Form.useForm()
  const formRef = useRef(null)

  const [selectedFeatures, setSelectedFeatures] = useState({})
  const [messageApi, contextHolder] = message.useMessage()

  const errorMsg = useCallback(
    (msg) => {
      messageApi.open({
        type: 'error',
        content: msg,
      })
    },
    [messageApi],
  )

  useEffect(() => {
    apiClient
      .get(`/v1/features`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setFeatures(res.data))
      .catch((err) => console.error('Error fetching features:', err))
  }, [token])

  const onsubmitForm = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    async ({ name, displayName, description, price, currency, level, isActive, visibility }) => {
      try {
        const planData = {
          name,
          displayName,
          description,
          price,
          currency,
          level,
          isActive: isActive === true,
          visibility: visibility === true,
          isDefault: false,
        }

        const selectedFeaturesData = features.map((feature) => ({
          id: feature.id,
          name: feature.name,
          value: Number(selectedFeatures[feature.id]) || 0,
        }))

        const requestData = { ...planData, features: selectedFeaturesData }

        const response = await apiClient.post('/v1/plans', requestData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        if (response.status === 200) {
          Modal.success({
            title: '플랜 생성이 정상적으로 되었습니다.',
            onOk: () => {
              navigate('/plan')
            },
          })
          formRef.current?.resetFields()
        }
      } catch (error) {
        console.error('Error creating plan:', error)
        errorMsg(error.message)
      }
    },
    [features, token, selectedFeatures, navigate, errorMsg],
  )

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <div>
      {contextHolder}

      <Form
        {...formItemLayout}
        layout="vertical"
        form={form}
        ref={formRef}
        name="register-user"
        onFinish={onsubmitForm}
        onFinishFailed={onFinishFailed}
        style={{
          maxWidth: 360,
          margin: '0 auto',
        }}
        scrollToFirstError
        validateMessages={validateMessages}
      >
        <Form.Item
          name="name"
          label="Plan Name"
          rules={[
            {
              type: 'name',
              required: true,
            },
          ]}
        >
          <Input type="text" placeholder="Plan Name" value={displayName} onChange={onChangeDisplayName} allowClear />
        </Form.Item>
        <Form.Item
          name="displayName"
          label="Display Name"
          rules={[
            {
              type: 'displayName',
              required: true,
            },
          ]}
        >
          <Input type="text" placeholder="Display Name" value={name} onChange={onChangeName} allowClear />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[
            {
              type: 'description',
            },
          ]}
        >
          <Input type="text" placeholder="Description" value={description} onChange={onChangeDescription} allowClear />
        </Form.Item>
        <Form.Item
          name="price"
          label="Price"
          rules={[
            {
              type: 'price',
              required: true,
            },
          ]}
        >
          <Input type="number" value={price} onChange={onChangePrice} placeholder="Price" allowClear />
        </Form.Item>
        <Form.Item
          name="currency"
          label="Currency"
          rules={[
            {
              type: 'currency',
              required: true,
            },
          ]}
        >
          <Select placeholder="Select a option and change input text above" allowClear>
            <Option value="KRW">KRW</Option>
            <Option value="USD">USD</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="level"
          label="Level"
          rules={[
            {
              type: 'level',
              required: true,
            },
          ]}
        >
          <Input type="number" value={level} onChange={onChangeLevel} placeholder="Level" allowClear />
        </Form.Item>
        <Form.Item
          name="isActive"
          label="Activation"
          rules={[
            {
              type: 'activation',
              required: true,
            },
          ]}
        >
          <Radio.Group>
            <Radio value>True</Radio>
            <Radio value={false}>False</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="visibility"
          label="Visibility"
          rules={[
            {
              type: 'visibility',
              required: true,
            },
          ]}
        >
          <Radio.Group>
            <Radio value>True</Radio>
            <Radio value={false}>False</Radio>
          </Radio.Group>
        </Form.Item>

        {features.map((feature) => (
          <Form.Item
            key={feature.id}
            name={feature.name}
            label={feature.name}
            rules={[
              {
                type: 'integer',
              },
            ]}
          >
            <InputNumber
              placeholder={feature.name}
              onChange={(value) => {
                setSelectedFeatures((prev) => ({
                  ...prev,
                  [feature.id]: value,
                }))
              }}
            />
          </Form.Item>
        ))}

        <Form.Item
          wrapperCol={{
            ...layout.wrapperCol,
            offset: 0,
          }}
        >
          <Button type="primary" htmlType="submit" block>
            Create
          </Button>
        </Form.Item>

        {/* List link */}
        <Row justify="end">
          <Col>
            <Link to="/plan" style={{ fontSize: '16px' }}>
              List
            </Link>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default withTitleMetaData(CreatePlan)
