import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Button, Col, Row, Table, Typography } from 'antd'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { FormOutlined } from '@ant-design/icons'
import { NumberParam, useQueryParam } from 'use-query-params'
import { getAllVoices } from '../../../Actions'
import withTitleMetaData from '../../../hocs/withTitleMetaData'

const { Text } = Typography

function Voices() {
  // Voices.propTypes = {
  //   title: PropTypes.string,
  // }

  const [posts, setPosts] = useState([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageable, setPageable] = useState({})
  const [totalElements, setTotalElements] = useState(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalPages, setTotalPages] = useState(null)
  const [page, setPage] = useQueryParam('page', NumberParam)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLoading, isError, error, isFetching, isPreviousData, data } = useQuery({
    queryKey: ['getAllVoices', page],
    queryFn: () => getAllVoices(page ? page - 1 : 0),
    staleTime: 500,
    keepPreviousData: false,
    onError: (e) => {
      // API 연결이 실패한 경우에 호출됨
      console.log(e.message)
    },
  })

  useEffect(() => {
    if (data) {
      setPosts(data.content)
      setPageable(data.pageable)
      setTotalElements(data.totalElements)
      setTotalPages(data.totalPages - 1)
    }
  }, [data])

  // const queryClient = useQueryClient()

  // useEffect(() => {
  //   const nextPage = page + 1
  //   queryClient.prefetchQuery(['getAllVoices', nextPage], () => getAllVoices(nextPage)) // 다음 페이지의 데이터를 미리 fetch
  // }, [page, queryClient])

  const columns = [
    {
      key: 'id',
      title: 'Id',
      dataIndex: 'id',
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
    },
    {
      key: 'displayName',
      title: 'Display Name',
      dataIndex: 'displayName',
    },
    {
      key: 'language',
      title: '언어',
      dataIndex: 'language',
      width: '10%',
    },
    {
      key: 'gender',
      title: '성별',
      dataIndex: 'gender',
      width: '10%',
    },
    {
      key: 'available',
      title: 'Available',
      dataIndex: 'available',
      render: (t, r) => <Text type="secondary">{r.available && r.available ? 'true' : 'false'}</Text>,
      width: '10%',
    },
    {
      key: 'path',
      title: 'Sample',
      dataIndex: 'path',
      render: (t, r) => (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio controls>
          <source src={r.sampleSrc} type="audio/mpeg" />
        </audio>
      ),
    },
    {
      key: 'tags',
      title: '태그',
      dataIndex: 'tags',
    },
    {
      key: 'engineCode',
      title: '엔진 코드',
      dataIndex: 'engineCode',
    },
    {
      key: 'createdAt',
      title: 'Creation date',
      dataIndex: 'createdAt',
      render: (t, r) => <Text type="secondary">{moment(r.createdAt).format('YYYY-MM-DD HH:mm')}</Text>,
      width: '10%',
    },
  ]

  return (
    <div>
      <Row justify="end">
        <Col>
          <Link to="/resources/voices/create">
            <Button type="primary" size="large">
              <FormOutlined /> 목소리 등록
            </Button>
          </Link>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={posts}
        rowKey={(record) => record.id}
        loading={isLoading}
        pagination={{
          total: totalElements,
          current: page,
          onChange: (newPage) => setPage(newPage),
          position: ['bottomCenter'],
        }}
      />
    </div>
  )
}

export default withTitleMetaData(Voices)
