import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Table, Typography, message, Tag } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { NumberParam, useQueryParam } from 'use-query-params'
import { FileSearchOutlined } from '@ant-design/icons'
import { getWorkspaces } from '../../Actions'
import withTitleMetaData from '../../hocs/withTitleMetaData'

const { Text } = Typography

function Workspaces() {
  // Workspaces.propTypes = {
  //   title: PropTypes.string,
  // }

  const [posts, setPosts] = useState([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageable, setPageable] = useState({})
  const [totalElements, setTotalElements] = useState(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalPages, setTotalPages] = useState(null)
  const [page, setPage] = useQueryParam('page', NumberParam)

  const {
    isLoading: isLoadingQuery,
    error,
    data,
  } = useQuery({
    queryKey: ['getWorkspaces', page],
    queryFn: () => getWorkspaces(page ? page - 1 : 0),
    staleTime: 500,
    keepPreviousData: true,
    onError: (e) => {
      // API 연결이 실패한 경우에 호출됨
      console.log(e.message)
    },
  })

  const isLoading = isLoadingQuery

  useEffect(() => {
    if (data) {
      setPosts(data.content)
      setPageable(data.pageable)
      setTotalElements(data.totalElements)
      setTotalPages(data.totalPages - 1)
    }
  }, [data])

  // const [drawerWorkspaceId, setDrawerWorkspaceId] = useState(null)

  // const showDrawer = async (record) => {
  //   setDrawerWorkspaceId(record.id)
  // }

  // const onCloseDrawer = () => {
  //   setDrawerWorkspaceId(null)
  // }

  const getTagColor = (status) => {
    switch (status) {
      case 'ACTIVE':
        return 'green'
      case 'INACTIVE':
        return 'red'
      case 'PAUSE':
        return 'orange'
      case 'CHANGED':
        return 'blue'
      default:
        return 'gray'
    }
  }

  const columns = [
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id',
      width: '7%',
    },
    {
      title: 'Name',
      key: 'Name',
      dataIndex: 'name',
      width: '50%',
    },
    {
      title: 'Subscription',
      dataIndex: 'subscription',
      key: 'subscription',
      render: (subscription) => <Tag color={getTagColor(subscription?.status)}>{subscription?.status}</Tag>,
    },
    /* {
      key: "history",
      title: "History",
      render: (t, r) => (
          <Link to={`${r.id}`}>
            <FileSearchOutlined />
          </Link>
      ),
    }, */
    {
      title: 'Creation date',
      key: 'Creation date',
      dataIndex: 'createdAt',
      render: (t, r) => <Text type="secondary">{moment(r.createdAt).format('YYYY-MM-DD HH:mm')}</Text>,
      width: '20%',
    },
    {
      key: 'View',
      title: 'View',
      render: (t, r) => (
        <Link to={`/workspace/${r.id}`}>
          <FileSearchOutlined />
        </Link>
      ),
    },
  ]

  const [messageApi, contextHolder] = message.useMessage()

  /**
   * API 에러 메시지 UI
   * @param msg
   */
  const errorMsg = (msg) => {
    messageApi.open({
      type: 'error',
      content: msg,
    })
  }

  return (
    <>
      <div>
        {contextHolder}
        {error && errorMsg(error.message)}

        <Table
          columns={columns}
          dataSource={posts}
          rowKey={(record) => record.id}
          loading={isLoading}
          pagination={{
            total: totalElements,
            current: page,
            onChange: (newPage) => setPage(newPage),
            position: ['bottomCenter'],
          }}
        />
      </div>
      {/* <Drawer
        title={drawerWorkspaceId || 'Workspace Details'}
        placement="right"
        width={600}
        onClose={onCloseDrawer}
        open={!!drawerWorkspaceId}
      >
        <Workspace workspaceId={drawerWorkspaceId} brief />
      </Drawer> */}
    </>
  )
}
export default withTitleMetaData(Workspaces)
