import React from 'react'
import { Layout } from 'antd'

const { Footer } = Layout
function FooterComponent() {
  return (
    <Footer
      style={{
        textAlign: 'center',
      }}
    >
      Copyright© AIPark
    </Footer>
  )
}
export default FooterComponent
