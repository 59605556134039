import React, { useState, useEffect, useCallback } from 'react'
import { Layout, Menu, theme, Typography, Button } from 'antd'
import { useLocation } from 'react-router-dom'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { useAuthState } from '../../atoms/auth'
import asideItems from '../Menuitems/asideItems'
import env from '../../constants/env'

const { Sider } = Layout
const rootSubmenuKeys = ['workspace:0', 'users:0', 'team:0', 'project:0', 'audio:0', 'video:0', 'plan:0', 'res:0']

function Aside({ changeLayoutMargin }) {
  const isLocation = useLocation()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [authState, setAuthState] = useAuthState()

  const [openKeys, setOpenKeys] = useState([''])
  const [defaultKeys, setDefaultKeys] = useState([''])

  const onOpenChange = (keys) => {
    const latestOpenKeys = keys.find((key) => openKeys.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKeys) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKeys ? [latestOpenKeys] : [])
    }
  }

  const [collapsed, setCollapsed] = useState(false)
  const {
    token: { colorBgContainer },
  } = theme.useToken()

  const getOpenKeys = useCallback(
    (modelId) => {
      switch (isLocation.pathname) {
        case '/':
          setOpenKeys(['dashboard'])
          setDefaultKeys(['dashboard'])
          break
        case '/dashboard':
          setOpenKeys(['dashboard'])
          setDefaultKeys(['dashboard'])
          break
        // 워크스페이스
        case '/workspace':
          setDefaultKeys(['workspace:1'])
          break
        // 회원
        case '/user':
          setDefaultKeys(['user:1'])
          break
        case '/user/register':
          setDefaultKeys(['user:2'])
          break
        // 조직
        case '/group':
          setDefaultKeys(['group:1'])
          break
        // 프로젝트
        case '/project':
          setDefaultKeys(['project:1'])
          break
        case '/project/create':
          setDefaultKeys(['project:2'])
          break
        // 오디오
        case '/audio':
          setDefaultKeys(['audio:1'])
          break
        // 비디오
        case '/video':
          setDefaultKeys(['video:1'])
          break
        // plan
        case '/plan':
          setDefaultKeys(['plan:1'])
          break
        // 리소스
        case '/resources/voices':
          setDefaultKeys(['res:1'])
          break
        case '/resources/voices/create':
          setDefaultKeys(['res:1'])
          break
        case '/resources/videos':
          setDefaultKeys(['res:2'])
          break
        case '/resources/videos/create':
          setDefaultKeys(['res:2'])
          break
        case '/resources/models':
          setDefaultKeys(['res:3'])
          break
        case '/resources/models/create':
          setDefaultKeys(['res:3'])
          break
        case `/resources/models/update/${modelId}`:
          setDefaultKeys(['res:3'])
          break
        case `/resources/models/${modelId}`:
          setDefaultKeys(['res:3'])
          break
        case '/resources/aivatars':
          setDefaultKeys(['res:4'])
          break
        case '/resources/aivatars/create':
          setDefaultKeys(['res:4'])
          break
        case '/email':
          setDefaultKeys(['email:1'])
          break
        default:
          break
      }
    },
    [isLocation.pathname],
  )

  const getKey = useCallback(
    () =>
      isLocation.pathname === '/workspace'
        ? setOpenKeys(['workspace:0'])
        : isLocation.pathname === '/user'
          ? setOpenKeys(['user:0'])
          : isLocation.pathname === '/group'
            ? setOpenKeys(['group:0'])
            : isLocation.pathname === '/project'
              ? setOpenKeys(['project:0'])
              : isLocation.pathname === '/audio'
                ? setOpenKeys(['audio:0'])
                : isLocation.pathname === '/video'
                  ? setOpenKeys(['video:0'])
                  : isLocation.pathname === '/plan'
                    ? setOpenKeys(['plan:0'])
                    : isLocation.pathname === '/resources/voices' ||
                        isLocation.pathname === '/resources/voices/create' ||
                        isLocation.pathname === '/resources/videos' ||
                        isLocation.pathname === '/resources/videos/create' ||
                        isLocation.pathname === '/resources/models' ||
                        isLocation.pathname === '/resources/models/create' ||
                        isLocation.pathname.includes('/resources/models/') ||
                        isLocation.pathname === '/resources/aivatars' ||
                        isLocation.pathname === '/resources/aivatars/create'
                      ? setOpenKeys(['res:0'])
                      : null,
    [isLocation.pathname],
  )

  useEffect(() => {
    const data = localStorage.getItem('token')
    if (data) {
      setAuthState({ loggedIn: true, ...data })
    }
  }, [setAuthState])

  useEffect(() => {
    getKey()
    getOpenKeys()
    console.log('%c OPENKEYS_PATH', 'color: yellow', isLocation.pathname)
  }, [getKey, getOpenKeys, isLocation.pathname])

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        background: colorBgContainer,
        borderRight: '1px solid rgba(5, 5, 5, 0.06)',
      }}
    >
      <Typography align="center" style={{ marginBlock: '13px' }}>
        <img src={`${env.PUBLIC_URL}/assets/images/aipark-ci.png`} width="80" alt="AIPark" />
      </Typography>
      <Menu
        theme="light"
        defaultSelectedKeys={['1']}
        selectedKeys={defaultKeys}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        mode="inline"
        items={asideItems}
        style={{ borderRight: 'none' }}
      />

      <Button
        type="text"
        icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
        onClick={() => {
          setCollapsed(!collapsed)
          changeLayoutMargin(80)
        }}
        style={{
          position: 'absolute',
          bottom: 0,
          fontSize: '20px',
          width: '100%',
          height: 56,
          marginTop: 56,
          marginLeft: 0,
        }}
      />
    </Sider>
  )
}
export default Aside
