import { useQuery } from '@tanstack/react-query'
import { Table, Typography } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { userQueries } from '../../../Actions'

type Props = {
  userId: number
}

function UserWorkspaceList({ userId }: Props) {
  const { data } = useQuery({
    ...userQueries.detail(userId),
    select: (selectData) => selectData.workspaceList,
  })

  return (
    <div>
      {/* {JSON.stringify(data)} */}
      <Typography.Title level={3}>워크스페이스 리스트</Typography.Title>
      <Table
        dataSource={data}
        pagination={false}
        columns={[
          { title: 'workspaceId', dataIndex: 'workspaceId', key: 'workspaceId' },
          {
            title: 'workspaceName',
            dataIndex: 'workspaceName',
            key: 'workspaceName',
            render: (t, r: any) => <Link to={`/workspace/${r.workspaceId}`}>{r.workspaceName}</Link>,
          },
          { title: 'role', dataIndex: 'role', key: 'role' },
          { title: 'planId', dataIndex: 'planId', key: 'planId' },
          { title: 'planName', dataIndex: 'planName', key: 'planName' },
        ]}
      />
    </div>
  )
}

export default UserWorkspaceList
