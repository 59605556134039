import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Table, Tag, Typography } from 'antd'
import moment from 'moment'
import { NumberParam, useQueryParam } from 'use-query-params'
import { getAllAudios } from '../../Actions'
import withTitleMetaData from '../../hocs/withTitleMetaData'

const { Text } = Typography

function Audios() {
  // Audios.propTypes = {
  //   title: PropTypes.string,
  // }

  const [posts, setPosts] = useState([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageable, setPageable] = useState({})
  const [totalElements, setTotalElements] = useState(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalPages, setTotalPages] = useState(null)
  const [page, setPage] = useQueryParam('page', NumberParam)

  const {
    isLoading: isLoadingQuery,
    error,
    data,
  } = useQuery({
    queryKey: ['getAllAudios', page],
    queryFn: () => getAllAudios(page ? page - 1 : 0),
    staleTime: 500,
    keepPreviousData: true,
    onError: (e) => {
      // API 연결이 실패한 경우에 호출됨
      console.log(e.message)
    },
  })

  const isLoading = isLoadingQuery

  useEffect(() => {
    if (data) {
      setPosts(data.content)
      setPageable(data.pageable)
      setTotalElements(data.totalElements)
      setTotalPages(data.totalPages - 1)
    }
  }, [data])

  // const queryClient = useQueryClient()

  // useEffect(() => {
  //   const nextPage = currentPage + 1
  //   queryClient.prefetchQuery(['getAllAudios', nextPage], () => getAllAudios(nextPage - 1)) // 다음 페이지의 데이터를 미리 fetch
  // }, [currentPage, queryClient])

  const getTagColor = (status) => {
    switch (status) {
      case 'READY':
        return 'yellow'
      case 'FAIL':
        return 'red'
      case 'COMPLETED':
        return 'green'
      case 'PROCESSING':
        return 'orange'
      default:
        return 'gray'
    }
  }

  const columns = [
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id',
      width: '7%',
    },
    {
      key: 'voiceId',
      title: 'Voice',
      dataIndex: 'voiceId',
      width: '7%',
    },
    {
      key: 'sentenceId',
      title: 'Sentence',
      dataIndex: 'sentenceId',
      width: '7%',
    },
    {
      key: 'content',
      title: 'Content',
      dataIndex: 'content',
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (status) => {
        const color = getTagColor(status)
        return <Tag color={color}>{status || 'N/A'}</Tag>
      },
    },
    {
      key: 'createdAt',
      title: 'Creation date',
      dataIndex: 'createdAt',
      render: (t, r) => <Text type="default">{moment(r.createdAt).format('YYYY-MM-DD HH:mm')}</Text>,
    },
  ]

  return (
    <div>
      {isLoading && <div>Loading....</div>}
      {error && error.message}

      <Table
        columns={columns}
        dataSource={posts}
        rowKey={(record) => `${record.createdAt}-${Math.floor(Math.random() * 100)}${1}`}
        loading={isLoading}
        pagination={{
          total: totalElements,
          current: page,
          onChange: (newPage) => setPage(newPage),
          position: ['bottomCenter'],
        }}
      />
    </div>
  )
}

export default withTitleMetaData(Audios)
