import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Card, Button, List, Tooltip, Typography } from 'antd'
import { ArrowRightOutlined, SyncOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { getProjects } from '../../Actions/projectsApi'

const { Text } = Typography

function ProjectsWidget() {
  const [posts, setPosts] = useState([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [page, setPage] = useState(0)
  const { isLoading, refetch, data } = useQuery({
    queryKey: ['getProjects', page],
    queryFn: () => getProjects(page),
    onError: (error) => {
      console.log(error)
    },
  })

  useEffect(() => {
    if (data) {
      setPosts(data.content)
    }
  }, [data])

  return (
    <Card
      title={
        <Link to="/project">
          프로젝트 <ArrowRightOutlined />
        </Link>
      }
      headStyle={{ paddingInline: '16px' }}
      bodyStyle={{ padding: 0 }}
      loading={isLoading}
      extra={
        <Tooltip title="새로고침">
          <Button shape="circle" size="small" onClick={refetch} icon={<SyncOutlined />} loading={isLoading} />
        </Tooltip>
      }
    >
      <List itemLayout="vertical" size="small">
        {posts &&
          posts
            .map((project) => (
              <List.Item
                key={`${project.createdAt}-${Math.floor(Math.random() * 100)}${1}`}
                extra={<Text type="secondary">{project.createdAt.slice(0, 10)}</Text>}
              >
                {project.title}
              </List.Item>
            ))
            .slice(0, 5)}
      </List>
    </Card>
  )
}
export default ProjectsWidget
