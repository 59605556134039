import React, { useState } from 'react'
import { Button, Form, Input, Select, Modal, message, Col, Row, Upload } from 'antd'
import { Link } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'
import apiClient from '../../../service/api'
import useInput from '../../../hooks/useInput'
import withTitleMetaData from '../../../hocs/withTitleMetaData'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { Option } = Select

const layout = {
  labelCol: {
    span: 0,
  },
  wrapperCol: {
    span: 24,
  },
}

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
}

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} 은 필수 항목 입니다.',
  types: {
    name: '${label} 을 입력해 주세요.',
    thumbnail: '${label} 를 등록해 주세요.',
    gender: '${label} 를 등록해 주세요.',
  },
}
/* eslint-enable no-template-curly-in-string */

function CreateAivatar() {
  // CreateAivatar.propTypes = {
  //   title: PropTypes.string,
  // }

  const [name, onChangeName] = useInput('')
  const [gender, onChangeGender] = useInput('')
  const [level, onChangeLevel] = useInput(0)
  const [displayOrder, onChangeDisplayOrder] = useInput(1)

  const [form] = Form.useForm()

  const [fileList, setFileList] = useState([])
  const [uploading, setUploading] = useState(false)

  const [messageApi, contextHolder] = message.useMessage()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const errorMsg = (msg) => {
    messageApi.open({
      type: 'error',
      content: msg,
    })
  }

  const fileProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file])
      return false
    },
    action: (file) => {
      console.log(file)
    },
    fileList,
  }

  const getFile = (e) => {
    console.log('Upload event:', e)
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const onFinish = (values) => {
    const token = localStorage.getItem('token')
    const formData = new FormData()
    Object.entries(values).forEach(([fieldName, fieldValue]) => {
      if (fieldName === 'file') {
        return
      }
      formData.append(fieldName, fieldValue) // there should be values.avatar which is a File object
    })

    // todo: upload file이 없으면!
    formData.append('file', values.file[0].originFileObj)
    // adding path
    formData.append('thumbnail', values.file[0].name)
    setUploading(true)
    apiClient
      .post('/v1/resources/aivatars', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => {
        console.log(data)
        setFileList([])
        message.success('upload successfully.')
      })
      .catch(() => {
        message.error('upload failed.')
      })
      .finally(() => {
        setUploading(false)
        form.resetFields()
      })
  }

  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  const handleCancel = () => setPreviewOpen(false)
  const handlePreview = async (previewFile) => {
    const file = previewFile
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewOpen(true)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  }
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList)
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  )

  return (
    <div>
      {contextHolder}

      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>

      <Form
        {...formItemLayout}
        layout="vertical"
        form={form}
        encType="multipart/form-data"
        name="register-aivatars"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{
          maxWidth: 360,
          margin: '0 auto',
        }}
        scrollToFirstError
        validateMessages={validateMessages}
      >
        <Form.Item name="file" label="썸네일 등록" getValueFromEvent={getFile}>
          <Upload
            {...fileProps}
            maxCount={1}
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            accept="image/*"
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item
          name="name"
          label="이름"
          rules={[
            {
              type: 'name',
              required: true,
            },
          ]}
        >
          <Input type="text" placeholder="네임" value={name} onChange={onChangeName} allowClear />
        </Form.Item>
        <Form.Item
          name="gender"
          label="성별"
          rules={[
            {
              type: 'gender',
              required: true,
            },
          ]}
        >
          <Input type="text" placeholder="성별" value={gender} onChange={onChangeGender} allowClear />
        </Form.Item>
        <Form.Item
          name="level"
          label="레벨"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="number" value={level} onChange={onChangeLevel} placeholder="레벨 입력" allowClear />
        </Form.Item>
        <Form.Item
          name="displayOrder"
          label="정렬 순서"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            type="number"
            value={displayOrder}
            onChange={onChangeDisplayOrder}
            placeholder="정렬 순서"
            allowClear
          />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            ...layout.wrapperCol,
            offset: 0,
          }}
        >
          <Button type="primary" htmlType="submit" disabled={fileList.length === 0} loading={uploading} block>
            {uploading ? 'Uploading' : 'Create'}
          </Button>
        </Form.Item>

        <Row justify="end">
          <Col>
            <Link to="/resources/aivatars" style={{ fontSize: '16px' }}>
              List
            </Link>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default withTitleMetaData(CreateAivatar)
